<div class="deznav">
        <div class="deznav-scroll" [perfectScrollbar]>
                <ul class="metismenu" id="menu" metis-menu>
                        <li [ngClass]="{'mm-active': dashboardArray.indexOf(currentHref) > -1}"><a
                                        class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-networking"></i>
                                        <span class="nav-text">داشبورد</span>
                                </a>
                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': dashboardArray.indexOf(currentHref) > -1}">
                                        <li><a class="{{(currentHref == '/admin/index' || currentHref == '/admin') ? 'mm-active' : ''}}"
                                                        [routerLink]="['/admin/index']">داشبورد &nbsp; 1</a></li>
                                        <li><a [routerLink]="['/admin/index-2']"
                                                        class="{{(currentHref == '/admin/index-2') ? 'mm-active' : ''}}">داشبورد
                                                        &nbsp; 2</a></li>
                                        <li><a [routerLink]="['/admin/index-3']"
                                                        class="{{(currentHref == '/admin/index-3') ? 'mm-active' : ''}}">داشبورد
                                                        &nbsp; 3</a></li>

                                </ul>

                        </li>

                        <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                                <a [routerLink]="['timesheet']"
                                        class="{{(currentHref == '/timesheet/ServiceDLTimeSheet') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-notepad"></i>
                                        <span class="nav-text">تایم شیت مراکز خدماتی</span>
                                </a>

                        </li>

                        <li [ngClass]="{'mm-active': apsArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-television"></i>
                                        <span class="nav-text">شروع دوره</span>
                                </a>
                                <ul aria-expanded="false" [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > -1}">
                                        <li><a [routerLink]="['final-cost/FinalCostCycle']"
                                                        class="{{(currentHref == '/final-cost/FinalCostCycle') ? 'mm-active' : ''}}">دوره
                                                        بهای تمام شده</a>
                                        </li>
                                        <li><a [routerLink]="['final-cost/FinalCostCycleOffice']"
                                                        class="{{(currentHref == '/final-cost/FinalCostCycleOffice') ? 'mm-active' : ''}}">
                                                        دوره های واحد </a>
                                        </li>

                                </ul>




                        </li>

                        <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                                <a [routerLink]="['final-cost/FinalCostCycleOfficeAction']"
                                        class="{{(currentHref == '/final-cost/FinalCostCycleOfficeAction') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-notepad"></i>
                                        <span class="nav-text">عملیات دوره</span>
                                </a>

                        </li> 



                        <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                                <a [routerLink]="['final-cost/CostAmount']"
                                        class="{{(currentHref == '/final-cost/CostAmount') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-notepad"></i>
                                        <span class="nav-text">حسابداری</span>
                                </a>

                        </li> 

                        
                        <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                                <a [routerLink]="['final-cost/StockAmount']"
                                        class="{{(currentHref == '/final-cost/StockAmount') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-notepad"></i>
                                        <span class="nav-text">انبار</span>
                                </a>

                        </li> 


                        <li [ngClass]="{'mm-active': formsArray.indexOf(currentHref) > -1}">
                                <a [routerLink]="['final-cost/FinalCostFlockDetail']"
                                        class="{{(currentHref == '/final-cost/FinalCostFlockDetail') ? 'mm-active' : ''}}">
                                        <i class="flaticon-381-notepad"></i>
                                        <span class="nav-text">گله ها</span>
                                </a>

                        </li> 


                        <li [ngClass]="{'mm-active': chartsArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-controls-3"></i>
                                        <span class="nav-text">اطلاعات پایه</span>
                                </a>
                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}">
                                        <li><a [routerLink]="['accounting-base/DL']"
                                                        class="{{(currentHref == 'fcbase/DL') ? 'mm-active' : ''}}">DL</a>
                                        </li>
                        
                                        <li><a [routerLink]="['accounting-base/DLType']"
                                                        class="{{(currentHref == 'fcbase/DLType') ? 'mm-active' : ''}}">DLType</a>
                                        </li>
                        
                        
                                        <li><a [routerLink]="['accounting-base/SL']"
                                                        class="{{(currentHref == 'fcbase/SL') ? 'mm-active' : ''}}">SL</a>
                                        </li>
                        
                                        <li><a [routerLink]="['accounting/AccFlock']"
                                                        class="{{(currentHref == 'fcbase/AccFlock') ? 'mm-active' : ''}}">AccFlock</a>
                                        </li>
                        
                                </ul>
                        </li>



                        
                        <li [ngClass]="{'mm-active': chartsArray.indexOf(currentHref) > -1}">
                                <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-controls-3"></i>
                                        <span class="nav-text">اطلاعات پایه انبار</span>
                                </a>
                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': apsArray.indexOf(currentHref) > 1 && apsArray.indexOf(currentHref) < 5}">
                        
                                        <li><a [routerLink]="['inventory-base/Part']"
                                                        class="{{(currentHref == 'invbase/Part') ? 'mm-active' : ''}}">Part</a>
                                        </li>
                        
                                        <li><a [routerLink]="['inventory-base/PartGroup']"
                                                        class="{{(currentHref == 'invbase/PartGroup') ? 'mm-active' : ''}}">Part Group</a>
                                        </li>


                                        <li><a [routerLink]="['inventory-base/PartMasterGroup']"
                                                class="{{(currentHref == 'invbase/PartMasterGroup') ? 'mm-active' : ''}}">Part Master Group</a>
                                        </li>
                        
                                        <li><a [routerLink]="['inventory-base/Unit']"
                                                        class="{{(currentHref == 'invbase/Unit') ? 'mm-active' : ''}}">Unit</a>
                                        </li>
                        
                                        <li><a [routerLink]="['inventory-base/Stock']"
                                                        class="{{(currentHref == 'invbase/Stock') ? 'mm-active' : ''}}">Stock</a>
                                        </li>
                        
                                        <li><a [routerLink]="['inventory-base/StockType']"
                                                        class="{{(currentHref == 'invbase/StockType') ? 'mm-active' : ''}}">Stock Type</a>
                                        </li>
                        
                        
                                        <li><a [routerLink]="['inventory-base/StockInvoiceType']"
                                                        class="{{(currentHref == 'invbase/StockInvoiceType') ? 'mm-active' : ''}}">Stock Invoice Type</a>
                                        </li>
                                </ul>
                        </li>

                        <li [ngClass]="{'mm-active': dashboardArray.indexOf(currentHref) > -1}"><a
                                        class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-381-networking"></i>
                                        <span class="nav-text">گزارشات بهای تمام شده</span>
                                </a>
                                <ul aria-expanded="false"
                                        [ngClass]="{'mm-show': dashboardArray.indexOf(currentHref) > -1}">
                                        <li><a class="{{(currentHref == '/admin/index' || currentHref == '/admin') ? 'mm-active' : ''}}"
                                                        [routerLink]="['gpreport/GpDailyReport/morality']">گزارش
                                                        تلفات</a></li>
                                        <li><a [routerLink]="['/admin/index-2']"
                                                        class="{{(currentHref == '/admin/index-2') ? 'mm-active' : ''}}">گزراشات
                                                        محیطی</a></li>
                                </ul>

                        </li>
                </ul>


                <div class="copyright">
                        <br>
                        <p class="fs-12">طراحی با <span [ngClass]="{'': toggleIcon, 'heart-blast': !toggleIcon}"
                                        class="heart" (click)="toggleLoveIcon()"></span> انفورماتیک سیمرغ</p>
                </div>
        </div>
</div>