import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { MatrialTypeDto, Combo_MatrialTypeDto, List_MatrialTypeDto, PList_MatrialTypeDto} from './matrial-type.model'



const serviceName= "MatrialTypeService"


@Injectable({
 providedIn: 'root',
})
export class MatrialTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<MatrialTypeDto> {
   let returnObject = new MatrialTypeDto()
   await this.RepositoryService.getItemAsync('MatrialType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<MatrialTypeDto> {
   let returnObject = new MatrialTypeDto()
   await this.RepositoryService.getItemAsync('MatrialType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_MatrialType) => {
           returnObject = _MatrialType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_MatrialTypeDto>
{
   let returnList = new PList_MatrialTypeDto();
   await this.RepositoryService.getAllAsync('MatrialType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_MatrialTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_MatrialTypeDto> {
       let returnList = new PList_MatrialTypeDto();
     await  this.RepositoryService.getAllAsync('MatrialType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_MatrialTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_MatrialTypeDto: MatrialTypeDto): Promise<MatrialTypeDto> {
           let returnvalue: MatrialTypeDto = null;
           await this.RepositoryService.createAsync('MatrialType/Create_Custom', _MatrialTypeDto)
             .then((res) => {
                 returnvalue = res as MatrialTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_MatrialTypeDto: MatrialTypeDto): Promise<MatrialTypeDto> {
               let returnvalue: MatrialTypeDto = null;
               await this.RepositoryService.updateAsync('MatrialType/Update_Custom', _MatrialTypeDto)
                 .then((res) => {
                     returnvalue = res as MatrialTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('MatrialType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_MatrialTypeDto[]> {
 let returnvalue: Combo_MatrialTypeDto[] = [];
         await this.RepositoryService.getAllAsync('MatrialType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_MatrialTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
