

export class FlockDto {
    GUID: string;
    OfficeGUID: string;
    SubOfficeGUID: string;
    BreedID: number;
    BreedGUID: string;
    Name: string;
    isForecast: boolean;
    Description: string;
    StartDate: string;
    StartDateShamsi: string;
    HachDate: string;
    HachDateShamsi: string;
    CloseDate: string;
    CloseDateShamsi: string;
    AccCode:string;
}


export class Combo_FlockDto {
    GUID: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    BreedGUID: string;
    BreedName: string;
    Name: string;
    isForecast: boolean;
    Description: string;
    StartDate: string;
    StartDateShamsi: string;
    HachDate: string;
    HachDateShamsi: string;
    CloseDate: string;
    CloseDateShamsi: string;
    AccCode:string;


}


export class List_FlockDto {
    GUID: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    BreedGUID: string;
    BreedName: string;
    Name: string;
    isForecast: boolean;
    Description: string;
    StartDate: string;
    StartDateShamsi: string;
    HachDate: string;
    HachDateShamsi: string;
    CloseDate: string;
    CloseDateShamsi: string;

}
export class PList_FlockDto {
Data: List_FlockDto[];
TotalCount: number;
}





