import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';
import { Filter_GpFlockInventoryDto, GpFlockInventoryDto } from './gp-flock-inventory.model';





const serviceName = "GpFlockInventory"


@Injectable({
  providedIn: 'root',
})
export class GpFlockInventoryService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }


  async Get_All(_Filter_GpFlockInventoryDto: Filter_GpFlockInventoryDto ,pagingString = ''): Promise<GpFlockInventoryDto[]> {
    let returnList : GpFlockInventoryDto[]  = [];
    await this.RepositoryService.getAllExteraAsync('GpFlockInventory/Get_All/' , _Filter_GpFlockInventoryDto )
      .then((res) => {
        returnList = res['value'] as GpFlockInventoryDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



}
