

export class UserDto {
    GUID: string;
    Name: string;
    Username: string;
    Password: string;
    Email: string;
    PhoneNumber: string;
    isActive: boolean;

}


export class LoginDto {
    Username: string;
    Password: string;
}

export class List_UserDto {
    GUID: string;
    Name: string;
    Username: string;
    Password: string;
    Email: string;
    PhoneNumber: string;
    isActive: boolean;

}
export class PList_UserDto {
Data: List_UserDto[];
TotalCount: number;
}
