

export class ShareBaseValueDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    DL4ID: number;
    DL4GUID: string;
    DL5ID: number;
    DL5GUID: string;
    DL6ID: number;
    DL6GUID: string;
    ServiceDLID: number;
    ServiceDLGUID: string;
    Amount: number;

}


export class List_ShareBaseValueDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    DL4GUID: string;
    DL4Name: string;
    DL5GUID: string;
    DL5Name: string;
    DL6GUID: string;
    DL6Name: string;
    ServiceDLGUID: string;
    ServiceDLName: string;
    Amount: number;

}
export class PList_ShareBaseValueDto {
Data: List_ShareBaseValueDto[];
TotalCount: number;
}
