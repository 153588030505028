

export class AccTitleDto {
    GUID: string;
    Name: string;

}


export class Combo_AccTitleDto {
    GUID: string;
    Name: string;

}


export class List_AccTitleDto {
    GUID: string;
    Name: string;

}
export class PList_AccTitleDto {
Data: List_AccTitleDto[];
TotalCount: number;
}
