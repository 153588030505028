

export class CostAmountDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    SLID: number;
    SLGUID: string;
    DL4ID: number;
    DL4GUID: string;
    DL5ID: number;
    DL5GUID: string;
    DL6ID: number;
    DL6GUID: string;
    Amount: number;
    ServiceAmount: number;

}


export class List_CostAmountDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    SLGUID: string;
    SLName: string;
    SLAccCode: number;
    DL5GUID: string;
    DL5Name: string;
    DL5AccCode: number;
    DL6GUID: string;
    DL6Name: string;
    DL6AccCode: number;
    Amount: number;
    ServiceAmount: number;

}
export class PList_CostAmountDto {
Data: List_CostAmountDto[];
TotalCount: number;
}


export class FilterCostAmountDto{
    OfficeGUID: string;
    FinalCostCycleOfficeGUIDs: string[];
    SubOfficeGUIDs: string[];
    DLTypeGUID: string;
    DLGUIDs: string[];
    SLGUIDs: string[];
}


