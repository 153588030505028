

export class EggGroupDto {
    GUID: string;
    Name: string;

}


export class Combo_EggGroupDto {
    GUID: string;
    Name: string;

}


export class List_EggGroupDto {
    GUID: string;
    Name: string;

}
export class PList_EggGroupDto {
Data: List_EggGroupDto[];
TotalCount: number;
}
