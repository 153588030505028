

export class UserIdentityDto {
    ClientName: string;
    IsArchived: boolean;
    UserId: number;
    IPAddress: string;
    ExpiresAt: string;

}


export class List_UserIdentityDto {
    ClientName: string;
    IsArchived: boolean;
    UserId: number;
    IPAddress: string;
    ExpiresAt: string;

}
export class PList_UserIdentityDto {
Data: List_UserIdentityDto[];
TotalCount: number;
}
