

export class FinalCostCycleOfficeActivityLogDto {
    FinalCostCycleOfficeID: number;
    FinalCostCycleActivityID: number;

}


export class List_FinalCostCycleOfficeActivityLogDto {
    FinalCostCycleActivityID: number;
    FinalCostCycleActivityName: string;
    UserName: string;
    CreatedDate: string;
    CreatedDateShamsi: string;

}
export class PList_FinalCostCycleOfficeActivityLogDto {
Data: List_FinalCostCycleOfficeActivityLogDto[];
TotalCount: number;
}


