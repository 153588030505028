

export class ServiceSetAmountDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    SLID: number;
    SLGUID: string;
    DL4ID: number;
    DL4GUID: string;
    DL5ID: number;
    DL5GUID: string;
    DL6ID: number;
    DL6GUID: string;
    Amount: number;

}


export class List_ServiceSetAmountDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    SLGUID: string;
    SLName: string;
    DL4GUID: string;
    DL4Name: string;
    DL5GUID: string;
    DL5Name: string;
    DL6GUID: string;
    DL6Name: string;
    Amount: number;

}
export class PList_ServiceSetAmountDto {
Data: List_ServiceSetAmountDto[];
TotalCount: number;
}
