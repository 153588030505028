

export class NewChickDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    CompanyID: number;
    CompanyGUID: string;
    FlockID: number;
    FlockGUID: string;
    FactoryID: number;
    FactoryGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    ExternalFlockName: string;
    MotherWeekAge: number;
    InWayMortality: number;
    isLock: boolean;

}


export class List_NewChickDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    CompanyGUID: string;
    CompanyName: string;
    FlockGUID: string;
    FlockName: string;
    FactoryGUID: string;
    FactoryName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    ExternalFlockName: string;
    MotherWeekAge: number;
    InWayMortality: number;
    isLock: boolean;

}
export class PList_NewChickDto {
Data: List_NewChickDto[];
TotalCount: number;
}
