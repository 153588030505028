import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { StockTypeDto, Combo_StockTypeDto, List_StockTypeDto, PList_StockTypeDto } from './stock-type.model'



const serviceName = "StockTypeService"


@Injectable({
  providedIn: 'root',
})
export class StockTypeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<StockTypeDto> {
    let returnObject = new StockTypeDto()
    await this.RepositoryService.getItemAsync('StockType/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<StockTypeDto> {
    let returnObject = new StockTypeDto()
    await this.RepositoryService.getItemAsync('StockType/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_StockType) => {
          returnObject = _StockType;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_StockTypeDto> {
    let returnList = new PList_StockTypeDto();
    await this.RepositoryService.getAllAsync('StockType/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_StockTypeDto> {
    let returnList = new PList_StockTypeDto();
    await this.RepositoryService.getAllAsync('StockType/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_StockTypeDto: StockTypeDto): Promise<StockTypeDto> {
    let returnvalue: StockTypeDto = null;
    await this.RepositoryService.createAsync('StockType/Create_Custom', _StockTypeDto)
      .then((res) => {
        returnvalue = res as StockTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_StockTypeDto: StockTypeDto): Promise<StockTypeDto> {
    let returnvalue: StockTypeDto = null;
    await this.RepositoryService.updateAsync('StockType/Update_Custom', _StockTypeDto)
      .then((res) => {
        returnvalue = res as StockTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('StockType/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_StockTypeDto[]> {
    let returnvalue: Combo_StockTypeDto[] = [];
    await this.RepositoryService.getAllAsync('StockType/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_StockTypeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async SyncStockType(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('StockType/SyncStockType/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
