import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { FinalCostCycleOfficeActivityLogDto, List_FinalCostCycleOfficeActivityLogDto, PList_FinalCostCycleOfficeActivityLogDto } from './final-cost-cycle-office-activity-log.model'



const serviceName = "FinalCostCycleOfficeActivityLogService"


@Injectable({
  providedIn: 'root',
})
export class FinalCostCycleOfficeActivityLogService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }


  async Get_AllList(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<PList_FinalCostCycleOfficeActivityLogDto> {
    let returnList = new PList_FinalCostCycleOfficeActivityLogDto();
    await this.RepositoryService.getAllAsync('FinalCostCycleOfficeActivityLog/Get_All(FinalCostCycleOfficeGUID='+FinalCostCycleOfficeGUID+')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostCycleOfficeActivityLogDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


}
