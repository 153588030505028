

export class PartDto {
    GUID: string;
    PartGroupID: number;
    PartGroupGUID: string;
    UnitID: number;
    UnitGUID: string;
    Name: string;
    Code: number;
    isActive: boolean;

}


export class Combo_PartDto {
    GUID: string;
    PartGroupGUID: string;
    PartGroupName: string;
    UnitGUID: string;
    UnitName: string;
    Name: string;
    Code: number;
    isActive: boolean;

}


export class List_PartDto {
    GUID: string;
    PartGroupGUID: string;
    PartGroupName: string;
    UnitGUID: string;
    UnitName: string;
    Name: string;
    Code: number;
    isActive: boolean;

}
export class PList_PartDto {
Data: List_PartDto[];
TotalCount: number;
}
