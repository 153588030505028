import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { PartMasterGroupDto, Combo_PartMasterGroupDto, List_PartMasterGroupDto, PList_PartMasterGroupDto } from './part-master-group.model'



const serviceName = "PartMasterGroupService"


@Injectable({
  providedIn: 'root',
})
export class PartMasterGroupService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<PartMasterGroupDto> {
    let returnObject = new PartMasterGroupDto()
    await this.RepositoryService.getItemAsync('PartMasterGroup/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<PartMasterGroupDto> {
    let returnObject = new PartMasterGroupDto()
    await this.RepositoryService.getItemAsync('PartMasterGroup/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_PartMasterGroup) => {
          returnObject = _PartMasterGroup;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_PartMasterGroupDto> {
    let returnList = new PList_PartMasterGroupDto();
    await this.RepositoryService.getAllAsync('PartMasterGroup/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_PartMasterGroupDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_PartMasterGroupDto> {
    let returnList = new PList_PartMasterGroupDto();
    await this.RepositoryService.getAllAsync('PartMasterGroup/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_PartMasterGroupDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_PartMasterGroupDto: PartMasterGroupDto): Promise<PartMasterGroupDto> {
    let returnvalue: PartMasterGroupDto = null;
    await this.RepositoryService.createAsync('PartMasterGroup/Create_Custom', _PartMasterGroupDto)
      .then((res) => {
        returnvalue = res as PartMasterGroupDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_PartMasterGroupDto: PartMasterGroupDto): Promise<PartMasterGroupDto> {
    let returnvalue: PartMasterGroupDto = null;
    await this.RepositoryService.updateAsync('PartMasterGroup/Update_Custom', _PartMasterGroupDto)
      .then((res) => {
        returnvalue = res as PartMasterGroupDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('PartMasterGroup/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_PartMasterGroupDto[]> {
    let returnvalue: Combo_PartMasterGroupDto[] = [];
    await this.RepositoryService.getAllAsync('PartMasterGroup/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_PartMasterGroupDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async SyncPartMasterGroup(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('PartMasterGroup/SyncPartMasterGroup/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
