export class UserProfileDto{
    GUID: string;
    Name: string;
    email: string;
    PhoneNumber: string;
    isActive: boolean;
    SubOfficeNames: string;
    RoleNames: string;
    OldPassword: string;
    NewPassword: string;
    LastUserModifyProfile:string
    LastDateModifyProfile:string
}