export const environment = {
  production: true,
  EmptyGUID: '00000000-0000-0000-0000-000000000000',
  SoftwareGUID: '2e861f86-0047-4663-8241-00cb94a137e6',
  defaultLang: 'en',
  odataUrl: 'https://erpapi.seamorgh.com/odata/Seamorgh',
  apiUrl: 'https://erpapi.seamorgh.com/api',
  apiVersion: 'v1',
  downloadURL: 'https://erpapi.seamorgh.com/',
  homeRoot: '/FC/final-cost',
  url: 'http://finalcost.seamorgh.com/#/'
};
