

export class DLDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    SubOfficeID: number;
    SubOfficeGUID: string;
    DLTypeID: number;
    DLTypeGUID: string;
    Name: string;
    Abrivation: string;
    AccCode: number;
    isGeneral: boolean;
    isActive: boolean;

}


export class Combo_DLDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    DLTypeGUID: string;
    DLTypeName: string;
    Name: string;
    Abrivation: string;
    AccCode: number;
    isGeneral: boolean;
    isActive: boolean;

}


export class List_DLDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    DLTypeGUID: string;
    DLTypeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    Name: string;
    Abrivation: string;
    AccCode: number;
    isGeneral: boolean;
    isActive: boolean;

}
export class PList_DLDto {
Data: List_DLDto[];
TotalCount: number;
}
