

export class MatrialDto {
    GUID: string;
    AccCode: number;
    MatrialTypeGUID: string;
    Name: string;

}


export class Combo_MatrialDto {
    GUID: string;
    AccCode: number;
    MatrialTypeGUID: string;
    MatrialTypeName: string;
    Name: string;

}


export class List_MatrialDto {
    GUID: string;
    AccCode: number;
    MatrialTypeGUID: string;
    MatrialTypeName: string;
    Name: string;

}
export class PList_MatrialDto {
Data: List_MatrialDto[];
TotalCount: number;
}
