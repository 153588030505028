import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { AccFlockDto, Combo_AccFlockDto, Filter_FlockByFinalCostCycleOfficesAndSubOffices, List_AccFlockDto, PList_AccFlockDto } from './acc-flock.model'



const serviceName = "AccFlockService"


@Injectable({
  providedIn: 'root',
})
export class AccFlockService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<AccFlockDto> {
    let returnObject = new AccFlockDto()
    await this.RepositoryService.getItemAsync('AccFlock/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<AccFlockDto> {
    let returnObject = new AccFlockDto()
    await this.RepositoryService.getItemAsync('AccFlock/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_AccFlock) => {
          returnObject = _AccFlock;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_AccFlockDto> {
    let returnList = new PList_AccFlockDto();
    await this.RepositoryService.getAllAsync('AccFlock/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_AccFlockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_AccFlockDto> {
    let returnList = new PList_AccFlockDto();
    await this.RepositoryService.getAllAsync('AccFlock/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_AccFlockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_AccFlockDto: AccFlockDto): Promise<AccFlockDto> {
    let returnvalue: AccFlockDto = null;
    await this.RepositoryService.createAsync('AccFlock/Create_Custom', _AccFlockDto)
      .then((res) => {
        returnvalue = res as AccFlockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_AccFlockDto: AccFlockDto): Promise<AccFlockDto> {
    let returnvalue: AccFlockDto = null;
    await this.RepositoryService.updateAsync('AccFlock/Update_Custom', _AccFlockDto)
      .then((res) => {
        returnvalue = res as AccFlockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('AccFlock/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo_ByFinalCostCycleOfficesAndSubOffices(_Filter_FlockByFinalCostCycleOfficesAndSubOffices: Filter_FlockByFinalCostCycleOfficesAndSubOffices): Promise<Combo_AccFlockDto[]> {
    let returnvalue: Combo_AccFlockDto[] = [];
    await this.RepositoryService.getAllExteraAsync('AccFlock/LoadCombo_ByFinalCostCycleOfficesAndSubOffices/', _Filter_FlockByFinalCostCycleOfficesAndSubOffices)
      .then((res) => {
        returnvalue = res['value'] as Combo_AccFlockDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }


}



