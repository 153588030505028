

export class DLFlockDto {
    GUID: string;
    AccDLID: number;
    AccDLGUID: string;
    FlockID: number;
    FlockGUID: string;

}


export class Combo_DLFlockDto {
    GUID: string;
    AccDLGUID: string;
    AccDLName: string;
    FlockGUID: string;
    FlockName: string;

}


export class List_DLFlockDto {
    GUID: string;
    AccDLGUID: string;
    AccDLName: string;
    FlockGUID: string;
    FlockName: string;

}
export class PList_DLFlockDto {
Data: List_DLFlockDto[];
TotalCount: number;
}
