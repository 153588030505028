import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { StockAmountDto, FilterStockAmountDto, List_StockAmountDto, PList_StockAmountDto } from './stock-amount.model'



const serviceName = "StockAmountService"


@Injectable({
  providedIn: 'root',
})
export class StockAmountService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<StockAmountDto> {
    let returnObject = new StockAmountDto()
    await this.RepositoryService.getItemAsync('StockAmount/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<StockAmountDto> {
    let returnObject = new StockAmountDto()
    await this.RepositoryService.getItemAsync('StockAmount/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_StockAmount) => {
          returnObject = _StockAmount;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(_FilterStockAmountDto: FilterStockAmountDto , pagingString = ''): Promise<PList_StockAmountDto> {
    let returnList = new PList_StockAmountDto();
    await this.RepositoryService.getAllExteraAsync('StockAmount/Get_AllCustom/' + pagingString , _FilterStockAmountDto)
      .then((res) => {
        returnList.Data = res['value'] as List_StockAmountDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  



  async Get_AllList(pagingString = ''): Promise<PList_StockAmountDto> {
    let returnList = new PList_StockAmountDto();
    await this.RepositoryService.getAllAsync('StockAmount/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockAmountDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_StockAmountDto: StockAmountDto): Promise<StockAmountDto> {
    let returnvalue: StockAmountDto = null;
    await this.RepositoryService.createAsync('StockAmount/Create_Custom', _StockAmountDto)
      .then((res) => {
        returnvalue = res as StockAmountDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_StockAmountDto: StockAmountDto): Promise<StockAmountDto> {
    let returnvalue: StockAmountDto = null;
    await this.RepositoryService.updateAsync('StockAmount/Update_Custom', _StockAmountDto)
      .then((res) => {
        returnvalue = res as StockAmountDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('StockAmount/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Get_AllStockAmount(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<boolean> {
    let returnList: boolean = false;
    await this.RepositoryService.getItemAsync('StockAmount/Get_AllStockAmount(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }
}
