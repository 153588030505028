import { ErrorService } from 'src/app/shared/error.service';
import { Injectable } from '@angular/core';

import { from } from 'rxjs';

import { CurrentUser, UserInfo, LoginDto } from '../_models';
import { RepositoryService  } from '../_services';


@Injectable({ providedIn: 'root' })
export class AuthService {

  currentUser: CurrentUser;
  userInfo: UserInfo;

  constructor(private repositoryService: RepositoryService, 
    private errorService: ErrorService) {
    this.currentUser = new CurrentUser();
    this.userInfo = new UserInfo();

  }



  // tslint:disable-next-line:typedef
  signIn(Login: LoginDto): Promise<CurrentUser> {
    return new Promise((resolve, reject) => {
      this.repositoryService.login('Login', Login)
        .subscribe(
          (user) => {
            this.currentUser = user as CurrentUser;
            localStorage.setItem('SeamorghUser', JSON.stringify(user));
            resolve(this.currentUser);
          }
        );
    }).then((value) => {
      return value as CurrentUser;
    }).catch((error: any) => {
      this.errorService.Failed(error)
      return new CurrentUser;
    });;
  }

  setUser(currentUser: CurrentUser): void{
            this.currentUser = currentUser as CurrentUser;
           
  }

  // tslint:disable-next-line:typedef
  signOut() {
    // return from(this.auth.signOut());
    // localStorage.removeItem('SeamorghUser');
    // localStorage.removeItem('PMISPortfolioProjectTitle');
    // localStorage.removeItem('UserInvolvedCompanyComponentRoleGUID');
    // localStorage.removeItem('vien-themecolor');
    // localStorage.removeItem('UserInfo');
    // localStorage.removeItem('vien-themeradius');
    // localStorage.removeItem('PMISProjectGUID');
    // localStorage.removeItem('theme_lang');
    // localStorage.removeItem('PMISPortfolioGUID');
  }

  // tslint:disable-next-line:typedef
  register(credentials: LoginDto) {
    // return this.auth
    //   .createUserWithEmailAndPassword(credentials.email, credentials.password)
    //   .then(async ({ user }) => {
    //     user.updateProfile({
    //       displayName: credentials.displayName,
    //     });
    //     this.auth.updateCurrentUser(user);
    //     return user;
    //   });
  }

  // tslint:disable-next-line:typedef
  sendPasswordEmail(email: LoginDto) {
    // return this.auth.sendPasswordResetEmail(email).then(() => {
    //   return true;
    // });
  }

  // tslint:disable-next-line:typedef
  resetPassword(credentials: LoginDto) {
    // return this.auth
    //   .confirmPasswordReset(credentials.code, credentials.newPassword)
    //   .then((data) => {
    //     return data;
    //   });
  }

  // tslint:disable-next-line:typedef
  async getUser(): Promise<CurrentUser> {
    let User = this.currentUser;
    if (!User) {
      User = JSON.parse(String(localStorage.getItem('SeamorghUser')));
      this.currentUser = User;
    }
    return await User;
  }


  // async getUserInfo() {
  //   // let UserInfo = this.userInfo;
  //   // if (!UserInfo) {
  //   //   UserInfo = JSON.parse(localStorage.getItem('UserInfo'));
  //   //   this.userInfo = UserInfo;
  //   // }
  //   // return await UserInfo;
  // }


}
