import { AfterViewInit, Component, OnInit, HostListener, ViewChild} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { List_SubOfficeDto, List_UserRoleDto ,List_SoftwareDto} from 'src/app/components/Index';
import { SoftwareService, UserRoleService } from 'src/app/components/ServiceIndex';
import { UserInfo } from 'src/app/_models';
import { SubOfficeHandlerService } from 'src/app/_services/sub-office-handler.service';
import { environment } from 'src/environments/environment';
import {UserProfileComponent} from 'src/app/elements/header/user-profile/user-profile.component'
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@ViewChild('UserProfileComponent') _UserProfileComponent: UserProfileComponent;
	@ViewChild('UserProfileModal') UserProfileModal: ModalDirective;

	screenHeight: number;
	screenWidth: number;
	toggleChat: boolean = true;
	toggleSingle: boolean = true;
	Username = '';
	UserEmail= '';
	CurrentActiveSoftware=environment.SoftwareGUID;
	myUserRoleControl = new UntypedFormControl();

	constructor(
		protected UserRoleService: UserRoleService,
		protected subOfficeHandlerService: SubOfficeHandlerService,
		protected softwareService: SoftwareService
		) { 

			this.getScreenSize(null);
		}

	AllUserRoles: List_UserRoleDto[] = [];
	AllSoftwareAccessRow1:List_SoftwareDto[]=[];
	AllSoftwareAccessRow2:List_SoftwareDto[]=[];
	AllSoftwareAccessRow3:List_SoftwareDto[]=[];
	filteredUserRoles: Observable<List_UserRoleDto[]>;

	ngOnInit(): void {
		const currentUser = JSON.parse(String(localStorage.getItem('SeamorghUserInfo')));
		if(currentUser){
			const user = currentUser as UserInfo;
			this.Username = user.Name;
			this.UserEmail = user.Email;
		}
	}


	ngAfterViewInit(): void {
		this.LoadCombo();


		this.filteredUserRoles = this.myUserRoleControl.valueChanges
			.pipe(
				map(cUserRole => typeof cUserRole === 'string' ? cUserRole : cUserRole.OfficeName + ' - ' +cUserRole.SubOfficeName + ' - ' + cUserRole.RoleName),
				map(cUserRole => cUserRole !== null ? this._filterUserRole(cUserRole) : this.AllUserRoles.slice())
			);
	}
async LoadUserProfile(){
	this._UserProfileComponent.LoadFormData();
}

	displayUserRole(cUserRole?: List_UserRoleDto): string | undefined {
		return cUserRole ? cUserRole.OfficeName+' - ' +cUserRole.SubOfficeName+ ' - ' + cUserRole.RoleName : undefined;
	}

	private _filterUserRole(UserRoleName: string): List_UserRoleDto[] {
		const filterValue = UserRoleName.toLowerCase();
		return this.AllUserRoles.filter(s => s.OfficeName.toLowerCase().includes(filterValue) || s.SubOfficeName.toLowerCase().includes(filterValue) ||  s.RoleName.toLowerCase().includes(filterValue));
	}

	LoadCombo() {
		this.LoadUserRoles();
	}

	async LoadUserRoles() {
		const ActiveUserRoleGUID = String(localStorage.getItem('ActiveUserRoleGUID'));
		this.AllUserRoles = await this.UserRoleService.Get_AllAccessSubOffice()

		if (this.AllUserRoles?.length > 0) {


			if (ActiveUserRoleGUID !== 'null'  && ActiveUserRoleGUID !== undefined  && ActiveUserRoleGUID !== null ) {
				const cUserRole = this.AllUserRoles.filter(x => x.GUID == ActiveUserRoleGUID);
				if (cUserRole!== undefined && cUserRole[0].GUID !== undefined) {
					this.myUserRoleControl.setValue(cUserRole[0]);
					localStorage.setItem('ActiveUserRoleGUID', cUserRole[0].GUID);
					this.subOfficeHandlerService.ChangeSubOffice_Handler(cUserRole[0].OfficeGUID , cUserRole[0].SubOfficeGUID);
				}

			} else {
				const cUserRole = this.AllUserRoles[0];
				this.myUserRoleControl.setValue(cUserRole);
				localStorage.setItem('ActiveUserRoleGUID', cUserRole.GUID);
				this.subOfficeHandlerService.ChangeSubOffice_Handler(cUserRole.OfficeGUID , cUserRole.SubOfficeGUID);
			}
		}

		const _softwareAceess=await this.softwareService.Get_SoftwareAccess();
		if(_softwareAceess?.Data?.length>0){
		this.AllSoftwareAccessRow1=_softwareAceess.Data.slice(0,4);}
		if(_softwareAceess?.Data?.length>4){
		this.AllSoftwareAccessRow2=_softwareAceess.Data.slice(4,8);}
		if(_softwareAceess?.Data?.length>8){
		this.AllSoftwareAccessRow3=_softwareAceess.Data.slice(8,12);}

	}


	
	onSelectedUserRole(isSelected: boolean, UserRole: List_UserRoleDto): void {
		if (isSelected) {
			localStorage.setItem('ActiveUserRoleGUID', UserRole.GUID);
			this.subOfficeHandlerService.ChangeSubOffice_Handler(UserRole.OfficeGUID , UserRole.SubOfficeGUID);
		}
	  }


	togglechatbar() {
		this.toggleChat = !this.toggleChat;
	}
	singleChatWindow() {
		this.toggleSingle = !this.toggleSingle;
	}
	@HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        // const div =  document.getElementById('main-wrapper');
        // if(this.screenWidth <768) {
        //     document.body.setAttribute('data-sidebar-style', 'overlay');
        // } else if(this.screenWidth >=768 && this.screenWidth <=1023) {
        //     document.body.setAttribute('data-sidebar-style', 'mini');
        // } else {
        //     document.body.setAttribute('data-sidebar-style', 'full');
        // }
    }

}
