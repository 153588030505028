

export class ShareBasePercentDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    DL4ID: number;
    DL4GUID: string;
    ServiceDLID: number;
    ServiceDLGUID: string;
    DL5ID: number;
    DL5GUID: string;
    DL6ID: number;
    DL6GUID: string;
    YearSum: number;
    CumSum: number;
    FirstSharePercent: number;
    ServiceSharePercent: number;
    TotalSharePercent: number;

}


export class List_ShareBasePercentDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    DL4GUID: string;
    DL4Name: string;
    ServiceDLGUID: string;
    ServiceDLName: string;
    DL5GUID: string;
    DL5Name: string;
    DL6GUID: string;
    DL6Name: string;
    YearSum: number;
    CumSum: number;
    FirstSharePercent: number;
    ServiceSharePercent: number;
    TotalSharePercent: number;

}
export class PList_ShareBasePercentDto {
Data: List_ShareBasePercentDto[];
TotalCount: number;
}
