

export class CompanyDto {
    GUID: string;
    OfficeGUID: string;
    SubOfficeGUID: string;
    Name: string;
    Address: string;

}


export class Combo_CompanyDto {
    GUID: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    Name: string;
    Address: string;

}


export class List_CompanyDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    Name: string;
    Address: string;

}
export class PList_CompanyDto {
Data: List_CompanyDto[];
TotalCount: number;
}
