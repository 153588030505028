import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { RationDto, List_RationDto, PList_RationDto} from './ration.model'
import { Filter_RationDto } from './filter-ration.model'


const serviceName= "RationService"


@Injectable({
 providedIn: 'root',
})
export class RationService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<RationDto> {
   let returnObject = new RationDto()
   await this.RepositoryService.getItemAsync('Ration/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<RationDto> {
   let returnObject = new RationDto()
   await this.RepositoryService.getItemAsync('Ration/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_Ration) => {
           returnObject = _Ration;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_RationDto>
{
   let returnList = new PList_RationDto();
   await this.RepositoryService.getAllAsync('Ration/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_RationDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_RationDto> {
       let returnList = new PList_RationDto();
     await  this.RepositoryService.getAllAsync('Ration/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_RationDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_RationDto: RationDto): Promise<RationDto> {
           let returnvalue: RationDto = null;
           await this.RepositoryService.createAsync('Ration/Create_Custom', _RationDto)
             .then((res) => {
                 returnvalue = res as RationDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_RationDto: RationDto): Promise<RationDto> {
               let returnvalue: RationDto = null;
               await this.RepositoryService.updateAsync('Ration/Update_Custom', _RationDto)
                 .then((res) => {
                     returnvalue = res as RationDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('Ration/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

 async Get_AllListFilter(_Filter_RationDto: Filter_RationDto, pagingString = ''): Promise<PList_RationDto> {
  let returnList = new PList_RationDto();
       await    this.RepositoryService.getAllExteraAsync('Ration/Get_AllCustom/' + pagingString, _Filter_RationDto)
            .then((res) => {
                returnList.Data = res['value'] as List_RationDto[];
                returnList.TotalCount = res['@odata.count'] as number;
            }).catch (
      (error) => {
              this.errorService.Failed(error);
              return undefined;
          }
    );
          return returnList;
          }
}
