import { Component, OnInit } from '@angular/core';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-startup-page',
  templateUrl: './startup-page.component.html',
  styleUrls: ['./startup-page.component.css']
})
export class StartupPageComponent implements OnInit {
  title = 'ERP';
  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;

    constructor(public sharedService: SharedService) {
      
    }

  ngOnInit(): void {
  }

}
