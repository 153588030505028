

export class CostCenterDto {
    GUID: string;
    Name: string;

}


export class Combo_CostCenterDto {
    GUID: string;
    Name: string;

}


export class List_CostCenterDto {
    GUID: string;
    Name: string;

}
export class PList_CostCenterDto {
Data: List_CostCenterDto[];
TotalCount: number;
}
