

export class LightColorDto {
    GUID: string;
    Name: string;

}


export class Combo_LightColorDto {
    GUID: string;
    Name: string;

}


export class List_LightColorDto {
    GUID: string;
    Name: string;

}
export class PList_LightColorDto {
Data: List_LightColorDto[];
TotalCount: number;
}
