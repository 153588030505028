

export class ChickMovingDto {
    GUID: string;
    FromHouseID: number;
    FromHouseGUID: string;
    ToHouseID: number;
    ToHouseGUID: string;
    ChickMovingTypeID: number;
    ChickMovingTypeGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

}


export class List_ChickMovingDto {
    GUID: string;
    FromHouseGUID: string;
    FromHouseName: string;
    ToHouseGUID: string;
    ToHouseName: string;
    ChickMovingTypeGUID: string;
    ChickMovingTypeName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

}
export class PList_ChickMovingDto {
Data: List_ChickMovingDto[];
TotalCount: number;
}
