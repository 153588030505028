

export class MortalityTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_MortalityTypeDto {
    GUID: string;
    Name: string;

}


export class List_MortalityTypeDto {
    GUID: string;
    Name: string;

}
export class PList_MortalityTypeDto {
Data: List_MortalityTypeDto[];
TotalCount: number;
}
