

export class BudgetFlockDto {
    GUID: string;
    BugetCycleID: number;
    BugetCycleGUID: string;
    FlockID: number;
    FlockGUID: string;
    Name: string;

}


export class List_BudgetFlockDto {
    GUID: string;
    BugetCycleGUID: string;
    BugetCycleName: string;
    FlockGUID: string;
    FlockName: string;
    Name: string;

}
export class PList_BudgetFlockDto {
Data: List_BudgetFlockDto[];
TotalCount: number;
}
