

export class OfficeDto {
    GUID: string;
    Name: string;
    AccCode: number;

}


export class Combo_OfficeDto {
    GUID: string;
    Name: string;
    AccCode: number;

}


export class List_OfficeDto {
    GUID: string;
    Name: string;
    AccCode: number;

}
export class PList_OfficeDto {
Data: List_OfficeDto[];
TotalCount: number;
}
