export class ServiceDLTimeSheetDto {
    GUID: string;
    SSubOfficeGUID: string;
    DSubOfficeGUID: string;
    ServiceDLGUID: string;
    sDate: string;
    ProduceData: ServiceDLTimeSheetDetailDto[];
    OfficeData: ServiceDLTimeSheetDetailDto[];
    ServiceData: ServiceDLTimeSheetDetailDto[];
}

export class ServiceDLTimeSheetDetailDto {
    GUID: string;
    DLGUID: string;
    DLName: string;
    AccCode: string;
    Amount: number;
}


export class List_ServiceDLTimeSheetDto {
    GUID: string;
    SSubOfficeGUID: string;
    SOfficeGUID: string;
    SSubOfficeName: string;
    SOfficeName: string;
    DSubOfficeGUID: string;
    DOfficeGUID: string;
    DSubOfficeName: string;
    DOfficeName: string;
    ServiceDLGUID: string;
    ServiceDLName: string;
    DL5GUID: string;
    DL5Name: string;
    DL6GUID: string;
    DL6Name: string;
    sDate: string;
    sDateShasi: string;
    Amount: number;
}
export class PList_ServiceDLTimeSheetDto {
Data: List_ServiceDLTimeSheetDto[];
TotalCount: number;
}


export class FilterServiceDLTimeSheetDto{
    OfficeGUID: string;
    SSubOfficeGUID: string;
    DSubOfficeGUID: string;
    ServiceDLGUID: string;
    FromDate:string;
    ToDate:string;
    ServiceDLType:string;
}