

export class EggStoreDto {
    GUID: string;
    Name: string;

}


export class Combo_EggStoreDto {
    GUID: string;
    Name: string;

}


export class List_EggStoreDto {
    GUID: string;
    Name: string;

}
export class PList_EggStoreDto {
Data: List_EggStoreDto[];
TotalCount: number;
}
