

//#region angular 
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//#endregion angular 



import {BaseListComponent} from './base-list/base-list.component';
import {BaseFormComponent} from './base-form/base-form.component';
import {AlertMessageComponent} from './alert-message/alert-message.component';
import {UploadDocumentComponent} from './upload-document/upload-document.component'

//#region provider 
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
//#endregion provider 




//#region internal Module components for declarations / exports

//#endregion

import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    BaseFormComponent,
    BaseListComponent,
    AlertMessageComponent,
    UploadDocumentComponent,
  ],
  exports: [
    AlertMessageComponent,
    BaseFormComponent,
    BaseListComponent,
    UploadDocumentComponent,
  ],
  imports: [
    SharedModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  
  ],
})
//TODO: با این اسم
export class GeneralSectionComponentModule { }
