import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { VitaminDto, List_VitaminDto, PList_VitaminDto, Save_VitaminDto } from './vitamin.model'
import { Filter_VitaminDto } from './filter-vitamin.model'


const serviceName = "VitaminService"


@Injectable({
  providedIn: 'root',
})
export class VitaminService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<VitaminDto> {
    let returnObject = new VitaminDto()
    await this.RepositoryService.getItemAsync('Vitamin/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<VitaminDto> {
    let returnObject = new VitaminDto()
    await this.RepositoryService.getItemAsync('Vitamin/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Vitamin) => {
          returnObject = _Vitamin;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_VitaminDto> {
    let returnList = new PList_VitaminDto();
    await this.RepositoryService.getAllAsync('Vitamin/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_VitaminDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllListFilter(_Filter_VitaminDto: Filter_VitaminDto, pagingString = ''): Promise<PList_VitaminDto> {
    let returnList = new PList_VitaminDto();
    await this.RepositoryService.getAllExteraAsync('Vitamin/Get_AllCustom/' + pagingString, _Filter_VitaminDto)
      .then((res) => {
        returnList.Data = res['value'] as List_VitaminDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(Data: Save_VitaminDto): Promise<PList_VitaminDto> {
    let returnList = new PList_VitaminDto();
    await this.RepositoryService.createAsync('Vitamin/Create_Custom', Data)
      .then((res) => {
        returnList.Data = res['value'] as List_VitaminDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnList;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Vitamin/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


}
