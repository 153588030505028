import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { FinalCostFlockDetailDto, FilterFinalCostFlockDetailDto, List_FinalCostFlockDetailDto, PList_FinalCostFlockDetailDto } from './final-cost-flock-detail.model'



const serviceName = "FinalCostFlockDetailService"


@Injectable({
  providedIn: 'root',
})
export class FinalCostFlockDetailService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<FinalCostFlockDetailDto> {
    let returnObject = new FinalCostFlockDetailDto()
    await this.RepositoryService.getItemAsync('FinalCostFlockDetail/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<FinalCostFlockDetailDto> {
    let returnObject = new FinalCostFlockDetailDto()
    await this.RepositoryService.getItemAsync('FinalCostFlockDetail/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_FinalCostFlockDetail) => {
          returnObject = _FinalCostFlockDetail;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(_FilterFinalCostFlockDetailDto: FilterFinalCostFlockDetailDto , pagingString = ''): Promise<PList_FinalCostFlockDetailDto> {
    let returnList = new PList_FinalCostFlockDetailDto();
    await this.RepositoryService.getAllExteraAsync('FinalCostFlockDetail/Get_AllCustom/' + pagingString , _FilterFinalCostFlockDetailDto)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostFlockDetailDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  



  async Get_AllList(pagingString = ''): Promise<PList_FinalCostFlockDetailDto> {
    let returnList = new PList_FinalCostFlockDetailDto();
    await this.RepositoryService.getAllAsync('FinalCostFlockDetail/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostFlockDetailDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_FinalCostFlockDetailDto: FinalCostFlockDetailDto): Promise<FinalCostFlockDetailDto> {
    let returnvalue: FinalCostFlockDetailDto = null;
    await this.RepositoryService.createAsync('FinalCostFlockDetail/Create_Custom', _FinalCostFlockDetailDto)
      .then((res) => {
        returnvalue = res as FinalCostFlockDetailDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_FinalCostFlockDetailDto: FinalCostFlockDetailDto): Promise<FinalCostFlockDetailDto> {
    let returnvalue: FinalCostFlockDetailDto = null;
    await this.RepositoryService.updateAsync('FinalCostFlockDetail/Update_Custom', _FinalCostFlockDetailDto)
      .then((res) => {
        returnvalue = res as FinalCostFlockDetailDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostFlockDetail/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Get_AllFinalCostFlockDetail(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<boolean> {
    let returnList: boolean = false;
    await this.RepositoryService.getItemAsync('FinalCostFlockDetail/Get_AllFinalCostFlockDetail(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }
}
