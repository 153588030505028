import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SLDto, Combo_SLDto, List_SLDto, PList_SLDto } from './sl.model'



const serviceName = "SLService"


@Injectable({
  providedIn: 'root',
})
export class SLService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SLDto> {
    let returnObject = new SLDto()
    await this.RepositoryService.getItemAsync('SL/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SLDto> {
    let returnObject = new SLDto()
    await this.RepositoryService.getItemAsync('SL/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_SL) => {
          returnObject = _SL;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_SLDto> {
    let returnList = new PList_SLDto();
    await this.RepositoryService.getAllAsync('SL/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SLDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_SLDto> {
    let returnList = new PList_SLDto();
    await this.RepositoryService.getAllAsync('SL/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SLDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_SLDto: SLDto): Promise<SLDto> {
    let returnvalue: SLDto = null;
    await this.RepositoryService.createAsync('SL/Create_Custom', _SLDto)
      .then((res) => {
        returnvalue = res as SLDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_SLDto: SLDto): Promise<SLDto> {
    let returnvalue: SLDto = null;
    await this.RepositoryService.updateAsync('SL/Update_Custom', _SLDto)
      .then((res) => {
        returnvalue = res as SLDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('SL/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_SLDto[]> {
    let returnvalue: Combo_SLDto[] = [];
    await this.RepositoryService.getAllAsync('SL/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_SLDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async SyncSL(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('SL/SyncSL/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
