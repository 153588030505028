

export class GpFlockDto {
    GUID: string;
    FlockID: number;
    FlockGUID: string;
    Name: string;

}


export class List_GpFlockDto {
    GUID: string;
    FlockGUID: string;
    FlockName: string;
    Name: string;
    StartDateShamsi: string;
    CloseDateShamsi: string;
    isClosed: boolean;

}
export class PList_GpFlockDto {
Data: List_GpFlockDto[];
TotalCount: number;
}

export class Combo_GpFlockDto {
    GUID: string;
    FlockGUID: string;
    FlockName: string;
    Name: string;
    isClosed: boolean;
    CloseDateShamsi: string
    }
