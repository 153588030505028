

export class HouseDto {
    GUID: string;
    GpFlockID: number;
    GpFlockGUID: string;
    SubOfficeHouseModelID: number;
    SubOfficeHouseModelGUID: string;
    SubOfficeHouseModelName: string;
    FertilizeTypeID: number;
    FertilizeTypeGUID: string;
    Name: string;
    isClosed: boolean;
    DateCloseShamsi: string;
    DateClose: string;
    UserCloseID: number;
    UserCloseGUID: string;

}


export class List_HouseDto {
    GUID: string;
    GpFlockGUID: string;
    GpFlockName: string;
    SubOfficeHouseModelGUID: string;
    SubOfficeHouseModelName: string;
    FertilizeTypeGUID: string;
    FertilizeTypeName: string;
    Name: string;
    isClosed: boolean;
    DateCloseShamsi: string;
    DateClose: string;
    UserCloseGUID: string;
    UserCloseName: string;

}
export class PList_HouseDto {
Data: List_HouseDto[];
TotalCount: number;
}
