

export class ComponentDto {
    GUID: string;
    SoftwareID: number;
    SoftwareGUID: string;
    Name: string;
    DisplayText: string;

}


export class Combo_ComponentDto {
    GUID: string;
    SoftwareGUID: string;
    SoftwareName: string;
    Name: string;
    DisplayText: string;

}


export class List_ComponentDto {
    GUID: string;
    SoftwareGUID: string;
    SoftwareName: string;
    Name: string;
    DisplayText: string;

}
export class PList_ComponentDto {
Data: List_ComponentDto[];
TotalCount: number;
}
