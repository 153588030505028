import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { RoleDto, Combo_RoleDto, List_RoleDto, PList_RoleDto } from './role.model'



const serviceName = "RoleService"


@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<RoleDto> {
    let returnObject = new RoleDto()
    await this.RepositoryService.getItemAsync('Role/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<RoleDto> {
    let returnObject = new RoleDto()
    await this.RepositoryService.getItemAsync('Role/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Role) => {
          returnObject = _Role;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_RoleDto> {
    let returnList = new PList_RoleDto();
    await this.RepositoryService.getAllAsync('Role/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RoleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_RoleDto> {
    let returnList = new PList_RoleDto();
    await this.RepositoryService.getAllAsync('Role/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_RoleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_RoleDto: RoleDto): Promise<RoleDto> {
    let returnvalue: RoleDto = null;
    await this.RepositoryService.createAsync('Role/Create_Custom', _RoleDto)
      .then((res) => {
        returnvalue = res as RoleDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_RoleDto: RoleDto): Promise<RoleDto> {
    let returnvalue: RoleDto = null;
    await this.RepositoryService.updateAsync('Role/Update_Custom', _RoleDto)
      .then((res) => {
        returnvalue = res as RoleDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Role/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo_BySoftwareGUID(SoftwareGUID: string): Promise<Combo_RoleDto[]> {
    let returnvalue: Combo_RoleDto[] = [];
    await this.RepositoryService.getAllAsync('Role/LoadCombo_BySoftwareGUID(SoftwareGUID='+SoftwareGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_RoleDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }



}
