

export class EggStatusDto {
    GUID: string;
    Name: string;

}


export class Combo_EggStatusDto {
    GUID: string;
    Name: string;

}


export class List_EggStatusDto {
    GUID: string;
    Name: string;

}
export class PList_EggStatusDto {
Data: List_EggStatusDto[];
TotalCount: number;
}
