

export class StockTypeDto {
    GUID: string;
    Name: string;
    Code: number;

}


export class Combo_StockTypeDto {
    GUID: string;
    Name: string;
    Code: number;
}


export class List_StockTypeDto {
    GUID: string;
    Name: string;
    Code: number;
}
export class PList_StockTypeDto {
Data: List_StockTypeDto[];
TotalCount: number;
}
