import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { OfficeDto, Combo_OfficeDto, List_OfficeDto, PList_OfficeDto } from './office.model'



const serviceName = "OfficeService"


@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<OfficeDto> {
    let returnObject = new OfficeDto()
    await this.RepositoryService.getItemAsync('Office/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<OfficeDto> {
    let returnObject = new OfficeDto()
    await this.RepositoryService.getItemAsync('Office/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Office) => {
          returnObject = _Office;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_OfficeDto> {
    let returnList = new PList_OfficeDto();
    await this.RepositoryService.getAllAsync('Office/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_OfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_OfficeDto> {
    let returnList = new PList_OfficeDto();
    await this.RepositoryService.getAllAsync('Office/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_OfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_OfficeDto: OfficeDto): Promise<OfficeDto> {
    let returnvalue: OfficeDto = null;
    await this.RepositoryService.createAsync('Office/Create_Custom', _OfficeDto)
      .then((res) => {
        returnvalue = res as OfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_OfficeDto: OfficeDto): Promise<OfficeDto> {
    let returnvalue: OfficeDto = null;
    await this.RepositoryService.updateAsync('Office/Update_Custom', _OfficeDto)
      .then((res) => {
        returnvalue = res as OfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Office/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_OfficeDto[]> {
    let returnvalue: Combo_OfficeDto[] = [];
    await this.RepositoryService.getAllAsync('Office/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_OfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
