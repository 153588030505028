

export class LightingProgramTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_LightingProgramTypeDto {
    GUID: string;
    Name: string;

}


export class List_LightingProgramTypeDto {
    GUID: string;
    Name: string;

}
export class PList_LightingProgramTypeDto {
Data: List_LightingProgramTypeDto[];
TotalCount: number;
}
