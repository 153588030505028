

export class UnitDto {
    GUID: string;
    Name: string;
    Code: number;

}


export class Combo_UnitDto {
    GUID: string;
    Name: string;
    Code: number;
}


export class List_UnitDto {
    GUID: string;
    Name: string;
    Code: number;
}
export class PList_UnitDto {
Data: List_UnitDto[];
TotalCount: number;
}
