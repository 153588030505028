import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { HouseDto, List_HouseDto, PList_HouseDto} from './house.model'



const serviceName= "House"


@Injectable({
 providedIn: 'root',
})
export class HouseService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<HouseDto> {
   let returnObject = new HouseDto()
   await this.RepositoryService.getItemAsync(serviceName + '/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<HouseDto> {
   let returnObject = new HouseDto()
   await this.RepositoryService.getItemAsync(serviceName + '/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_House) => {
           returnObject = _House;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_HouseDto>
{
   let returnList = new PList_HouseDto();
   await this.RepositoryService.getAllAsync(serviceName + '/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_HouseDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_HouseDto> {
       let returnList = new PList_HouseDto();
     await  this.RepositoryService.getAllAsync(serviceName + '/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_HouseDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }




  async Get_AllByGpFlockGUID(GpFlockGUID: String, pagingString = ''): Promise<PList_HouseDto> {
    let returnList = new PList_HouseDto();
    await this.RepositoryService.getAllAsync(serviceName + '/Get_AllByGpFlockGUID(GpFlockGUID=' + GpFlockGUID + ')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_HouseDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


 async Create_Custom(_HouseDto: HouseDto): Promise<HouseDto> {
           let returnvalue: HouseDto = null;
           await this.RepositoryService.createAsync(serviceName + '/Create_Custom', _HouseDto)
             .then((res) => {
                 returnvalue = res as HouseDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_HouseDto: HouseDto): Promise<HouseDto> {
               let returnvalue: HouseDto = null;
               await this.RepositoryService.updateAsync(serviceName + '/Update_Custom', _HouseDto)
                 .then((res) => {
                     returnvalue = res as HouseDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync(serviceName + '/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }
}
