import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({
  providedIn: 'root',
})
export class SubOfficeHandlerService {

  constructor() { }

   //CurrentOffice
   private _CurrentOfficeGUID: string;
   SetCurrentOfficeGUID(CurrentOfficeGUID: string) {
     this._CurrentOfficeGUID = CurrentOfficeGUID;
   }
   GetCurrentOfficeGUID() {
     return this._CurrentOfficeGUID;
   }
   //CurrentOffice

  //CurrentSubOffice
  private _CurrentSubOfficeGUID: string;
  SetCurrentSubOfficeGUID(CurrentOfficeGUID: string) {
    this._CurrentSubOfficeGUID = CurrentOfficeGUID;
  }
  GetCurrentSubOfficeGUID() {
    return this._CurrentSubOfficeGUID;
  }
  //CurrentSubOffice


    // Observable string sources
    private ChangeSubOffice = new Subject<string>();

    // Observable string streams
    Observable_ChangeSubOffice = this.ChangeSubOffice.asObservable();
  
    // Service message commands
    ChangeSubOffice_Handler(_OfficeGUID: string ,_SubOfficeGUID: string) {
      this.SetCurrentOfficeGUID(_OfficeGUID);
      this.SetCurrentSubOfficeGUID(_SubOfficeGUID);
      this.ChangeSubOffice.next();
    }


}
