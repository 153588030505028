

export class ChickWeightDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    AverageWeight: number;
    CV: number;
    Uniformity: number;
    MinWeight: number;
    MaxWeight: number;
    MaxThermometer3: number;
    BalanceAccuracy: number;
    OperatorName: string;
    Description: string;
    isLock: boolean;

}


export class List_ChickWeightDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    AverageWeight: number;
    CV: number;
    Uniformity: number;
    MinWeight: number;
    MaxWeight: number;
    MaxThermometer3: number;
    BalanceAccuracy: number;
    OperatorName: string;
    Description: string;
    isLock: boolean;

}
export class PList_ChickWeightDto {
Data: List_ChickWeightDto[];
TotalCount: number;
}
