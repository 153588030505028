

export class BugetCycleDto {
    GUID: string;
    Name: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;

}


export class List_BugetCycleDto {
    GUID: string;
    Name: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;

}
export class PList_BugetCycleDto {
Data: List_BugetCycleDto[];
TotalCount: number;
}
