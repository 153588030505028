

export class LifeCycleDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    LifeCycleTypeID: number;
    LifeCycleTypeGUID: string;
    sDateShamsi: string;
    sDate: string;
    isLock: boolean;

}


export class List_LifeCycleDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    sDateShamsi: string;
    sDate: string;
    isLock: boolean;

}
export class PList_LifeCycleDto {
Data: List_LifeCycleDto[];
TotalCount: number;
}
