

export class RationGroupDto {
    GUID: string;
    FlockTypeID: number;
    FlockTypeGUID: string;
    Name: string;

}


export class Combo_RationGroupDto {
    GUID: string;
    FlockTypeGUID: string;
    FlockTypeName: string;
    Name: string;

}


export class List_RationGroupDto {
    GUID: string;
    FlockTypeGUID: string;
    FlockTypeName: string;
    Name: string;

}
export class PList_RationGroupDto {
Data: List_RationGroupDto[];
TotalCount: number;
}
