

export class ChickMovingTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_ChickMovingTypeDto {
    GUID: string;
    Name: string;

}


export class List_ChickMovingTypeDto {
    GUID: string;
    Name: string;

}
export class PList_ChickMovingTypeDto {
Data: List_ChickMovingTypeDto[];
TotalCount: number;
}
