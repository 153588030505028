import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { MonthDto, Combo_MonthDto, List_MonthDto, PList_MonthDto} from './month.model'



const serviceName= "MonthService"


@Injectable({
 providedIn: 'root',
})
export class MonthService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<MonthDto> {
   let returnObject = new MonthDto()
   await this.RepositoryService.getItemAsync('Month/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<MonthDto> {
   let returnObject = new MonthDto()
   await this.RepositoryService.getItemAsync('Month/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_Month) => {
           returnObject = _Month;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_MonthDto>
{
   let returnList = new PList_MonthDto();
   await this.RepositoryService.getAllAsync('Month/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_MonthDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_MonthDto> {
       let returnList = new PList_MonthDto();
     await  this.RepositoryService.getAllAsync('Month/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_MonthDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_MonthDto: MonthDto): Promise<MonthDto> {
           let returnvalue: MonthDto = null;
           await this.RepositoryService.createAsync('Month/Create_Custom', _MonthDto)
             .then((res) => {
                 returnvalue = res as MonthDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_MonthDto: MonthDto): Promise<MonthDto> {
               let returnvalue: MonthDto = null;
               await this.RepositoryService.updateAsync('Month/Update_Custom', _MonthDto)
                 .then((res) => {
                     returnvalue = res as MonthDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('Month/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_MonthDto[]> {
 let returnvalue: Combo_MonthDto[] = [];
         await this.RepositoryService.getAllAsync('Month/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_MonthDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
