import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { FlockTypeDto, Combo_FlockTypeDto, List_FlockTypeDto, PList_FlockTypeDto} from './flock-type.model'



const serviceName= "FlockTypeService"


@Injectable({
 providedIn: 'root',
})
export class FlockTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<FlockTypeDto> {
   let returnObject = new FlockTypeDto()
   await this.RepositoryService.getItemAsync('FlockType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<FlockTypeDto> {
   let returnObject = new FlockTypeDto()
   await this.RepositoryService.getItemAsync('FlockType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_FlockType) => {
           returnObject = _FlockType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_FlockTypeDto>
{
   let returnList = new PList_FlockTypeDto();
   await this.RepositoryService.getAllAsync('FlockType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_FlockTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_FlockTypeDto> {
       let returnList = new PList_FlockTypeDto();
     await  this.RepositoryService.getAllAsync('FlockType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_FlockTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_FlockTypeDto: FlockTypeDto): Promise<FlockTypeDto> {
           let returnvalue: FlockTypeDto = null;
           await this.RepositoryService.createAsync('FlockType/Create_Custom', _FlockTypeDto)
             .then((res) => {
                 returnvalue = res as FlockTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_FlockTypeDto: FlockTypeDto): Promise<FlockTypeDto> {
               let returnvalue: FlockTypeDto = null;
               await this.RepositoryService.updateAsync('FlockType/Update_Custom', _FlockTypeDto)
                 .then((res) => {
                     returnvalue = res as FlockTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('FlockType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_FlockTypeDto[]> {
 let returnvalue: Combo_FlockTypeDto[] = [];
         await this.RepositoryService.getAllAsync('FlockType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_FlockTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
