import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SubOfficeDto, Combo_SubOfficeDto, List_SubOfficeDto, PList_SubOfficeDto } from './sub-office.model'



const serviceName = "SubOfficeService"


@Injectable({
  providedIn: 'root',
})
export class SubOfficeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SubOfficeDto> {
    let returnObject = new SubOfficeDto()
    await this.RepositoryService.getItemAsync('SubOffice/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SubOfficeDto> {
    let returnObject = new SubOfficeDto()
    await this.RepositoryService.getItemAsync('SubOffice/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_SubOffice) => {
          returnObject = _SubOffice;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_SubOfficeDto> {
    let returnList = new PList_SubOfficeDto();
    await this.RepositoryService.getAllAsync('SubOffice/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SubOfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  async Get_AllList(pagingString = ''): Promise<PList_SubOfficeDto> {
    let returnList = new PList_SubOfficeDto();
    await this.RepositoryService.getAllAsync('SubOffice/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SubOfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_SubOfficeDto: SubOfficeDto): Promise<SubOfficeDto> {
    let returnvalue: SubOfficeDto = null;
    await this.RepositoryService.createAsync('SubOffice/Create_Custom', _SubOfficeDto)
      .then((res) => {
        returnvalue = res as SubOfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_SubOfficeDto: SubOfficeDto): Promise<SubOfficeDto> {
    let returnvalue: SubOfficeDto = null;
    await this.RepositoryService.updateAsync('SubOffice/Update_Custom', _SubOfficeDto)
      .then((res) => {
        returnvalue = res as SubOfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('SubOffice/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_SubOfficeDto[]> {
    let returnvalue: Combo_SubOfficeDto[] = [];
    await this.RepositoryService.getAllAsync('SubOffice/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_SubOfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async GetCombo_ByOffice(OfficeGUID: string): Promise<Combo_SubOfficeDto[]> {
    let returnvalue: Combo_SubOfficeDto[] = [];
    await this.RepositoryService.getAllAsync('SubOffice/GetCombo_ByOffice(OfficeGUID=' + OfficeGUID + ')')
      .then((res) => {
        returnvalue = res['value'] as Combo_SubOfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }


  

  
  async GetCombo_ByOfficeFull(OfficeGUID: string): Promise<Combo_SubOfficeDto[]> {
    let returnvalue: Combo_SubOfficeDto[] = [];
    await this.RepositoryService.getAllAsync('SubOffice/GetCombo_ByOfficeFull(OfficeGUID=' + OfficeGUID + ')')
      .then((res) => {
        returnvalue = res['value'] as Combo_SubOfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }

}
