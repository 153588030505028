import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { UseMethodDto, Combo_UseMethodDto, List_UseMethodDto, PList_UseMethodDto} from './use-method.model'



const serviceName= "UseMethodService"


@Injectable({
 providedIn: 'root',
})
export class UseMethodService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<UseMethodDto> {
   let returnObject = new UseMethodDto()
   await this.RepositoryService.getItemAsync('UseMethod/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<UseMethodDto> {
   let returnObject = new UseMethodDto()
   await this.RepositoryService.getItemAsync('UseMethod/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_UseMethod) => {
           returnObject = _UseMethod;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_UseMethodDto>
{
   let returnList = new PList_UseMethodDto();
   await this.RepositoryService.getAllAsync('UseMethod/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_UseMethodDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_UseMethodDto> {
       let returnList = new PList_UseMethodDto();
     await  this.RepositoryService.getAllAsync('UseMethod/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_UseMethodDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_UseMethodDto: UseMethodDto): Promise<UseMethodDto> {
           let returnvalue: UseMethodDto = null;
           await this.RepositoryService.createAsync('UseMethod/Create_Custom', _UseMethodDto)
             .then((res) => {
                 returnvalue = res as UseMethodDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_UseMethodDto: UseMethodDto): Promise<UseMethodDto> {
               let returnvalue: UseMethodDto = null;
               await this.RepositoryService.updateAsync('UseMethod/Update_Custom', _UseMethodDto)
                 .then((res) => {
                     returnvalue = res as UseMethodDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('UseMethod/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_UseMethodDto[]> {
 let returnvalue: Combo_UseMethodDto[] = [];
         await this.RepositoryService.getAllAsync('UseMethod/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_UseMethodDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
