

export class RationDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    PartID: number;
    PartGUID: string;
    sDateShamsi: string;
    sDate: string;
    FeedingTime: string;
    isFeedShareBetweenHenAndRooster: boolean;
    HenRationWeight: number;
    RoosterRationWeight: number;
    Description: string;
    isLock: boolean;

}


export class List_RationDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    PartGUID: string;
    PartName: string;
    sDateShamsi: string;
    sDate: string;
    FeedingTime: string;
    isFeedShareBetweenHenAndRooster: boolean;
    HenRationWeight: number;
    RoosterRationWeight: number;
    Description: string;
    isLock: boolean;

}
export class PList_RationDto {
Data: List_RationDto[];
TotalCount: number;
}
