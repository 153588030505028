export class ComponentActionDto {
    GUID: string;
    ComponentGUID: string;
    Name: string;
    DisplayText: string;
}


export class Combo_ComponentActionDto {
    GUID: string;
    ComponentGUID: string;
    ComponentName: string;
    Name: string;
    DisplayText: string;

}


export class List_ComponentActionDto {
    GUID: string;
    ComponentGUID: string;
    ComponentName: string;
    Name: string;
    DisplayText: string;

}
export class PList_ComponentActionDto {
Data: List_ComponentActionDto[];
TotalCount: number;
}
