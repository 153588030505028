

export class PersonnelDto {
    GUID: string;
    FirstName: string;
    LastName: string;

}


export class List_PersonnelDto {
    GUID: string;
    FirstName: string;
    LastName: string;

}
export class PList_PersonnelDto {
Data: List_PersonnelDto[];
TotalCount: number;
}
