import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { ChickMovingDto, List_ChickMovingDto, PList_ChickMovingDto} from './chick-moving.model'
import { Filter_ChickMovingDto } from './filter-chick-moving.model'


const serviceName= "ChickMovingService"


@Injectable({
 providedIn: 'root',
})
export class ChickMovingService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ChickMovingDto> {
   let returnObject = new ChickMovingDto()
   await this.RepositoryService.getItemAsync('ChickMoving/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ChickMovingDto> {
   let returnObject = new ChickMovingDto()
   await this.RepositoryService.getItemAsync('ChickMoving/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ChickMoving) => {
           returnObject = _ChickMoving;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ChickMovingDto>
{
   let returnList = new PList_ChickMovingDto();
   await this.RepositoryService.getAllAsync('ChickMoving/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ChickMovingDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_ChickMovingDto> {
       let returnList = new PList_ChickMovingDto();
     await  this.RepositoryService.getAllAsync('ChickMoving/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ChickMovingDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ChickMovingDto: ChickMovingDto): Promise<ChickMovingDto> {
           let returnvalue: ChickMovingDto = null;
           await this.RepositoryService.createAsync('ChickMoving/Create_Custom', _ChickMovingDto)
             .then((res) => {
                 returnvalue = res as ChickMovingDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ChickMovingDto: ChickMovingDto): Promise<ChickMovingDto> {
               let returnvalue: ChickMovingDto = null;
               await this.RepositoryService.updateAsync('ChickMoving/Update_Custom', _ChickMovingDto)
                 .then((res) => {
                     returnvalue = res as ChickMovingDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ChickMoving/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

 async Get_AllListFilter(_Filter_ChickMovingDto: Filter_ChickMovingDto, pagingString = ''): Promise<PList_ChickMovingDto> {
  let returnList = new PList_ChickMovingDto();
       await    this.RepositoryService.getAllExteraAsync('ChickMoving/Get_AllCustom/' + pagingString, _Filter_ChickMovingDto)
            .then((res) => {
                returnList.Data = res['value'] as List_ChickMovingDto[];
                returnList.TotalCount = res['@odata.count'] as number;
            }).catch (
      (error) => {
              this.errorService.Failed(error);
              return undefined;
          }
    );
          return returnList;
          }
}
