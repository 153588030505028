import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/auth.service';
import { LoginService } from './login.service';
import { BaseFormComponent } from 'src/app/general-components/base-form/base-form.component';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from 'src/app/shared/error.service';
import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { LoginDto } from 'src/app/_models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  myForm = this.fb.group({
    Username: ['', [Validators.required]],
    Password: ['', [Validators.required]],
  });

  constructor(
    protected fb: UntypedFormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected translate: TranslateService,
    protected authService: AuthService,
    protected generalService: GeneralService,
    private service: LoginService,
  ) {

  }

  StartLogin = false;
  buttonDisabled = false;

  ngOnInit(): any {
    localStorage.removeItem('SeamorghUser');
    localStorage.removeItem('SeamorghUserInfo');


  }
  ngAfterViewInit(): void { }

  async onSubmit() {
    this.StartLogin = true;
    this.buttonDisabled = true;



    const login: LoginDto = new LoginDto();
    login.Username = this.myForm.controls.Username.value;
    login.Password = this.myForm.controls.Password.value;

    let isLogin = await this.service.login(login);
    if (isLogin) {
      await this.Get_UserInfo();
      this.router.navigate([environment.homeRoot]);
    }

    this.StartLogin = false;
    this.buttonDisabled = false;
  }


  async Get_UserInfo() {
    await this.service.Get_UserInfo();
  }

}
