import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SamplingDto, List_SamplingDto, PList_SamplingDto, Save_SamplingDto } from './sampling.model'
import { Filter_SamplingDto } from './filter-sampling.model'


const serviceName = "SamplingService"


@Injectable({
  providedIn: 'root',
})
export class SamplingService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SamplingDto> {
    let returnObject = new SamplingDto()
    await this.RepositoryService.getItemAsync('Sampling/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SamplingDto> {
    let returnObject = new SamplingDto()
    await this.RepositoryService.getItemAsync('Sampling/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Sampling) => {
          returnObject = _Sampling;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_SamplingDto> {
    let returnList = new PList_SamplingDto();
    await this.RepositoryService.getAllAsync('Sampling/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SamplingDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllListFilter(_Filter_SamplingDto: Filter_SamplingDto, pagingString = ''): Promise<PList_SamplingDto> {
    let returnList = new PList_SamplingDto();
    await this.RepositoryService.getAllExteraAsync('Sampling/Get_AllCustom/' + pagingString, _Filter_SamplingDto)
      .then((res) => {
        returnList.Data = res['value'] as List_SamplingDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(Data: Save_SamplingDto): Promise<PList_SamplingDto> {
    let returnList = new PList_SamplingDto();
    await this.RepositoryService.createAsync('Sampling/Create_Custom', Data)
      .then((res) => {
        returnList.Data = res['value'] as List_SamplingDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnList;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Sampling/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


}
