import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { ChickWeightDto, List_ChickWeightDto, PList_ChickWeightDto} from './chick-weight.model'
import { Filter_ChickWeightDto } from './filter-chick-weight.model'


const serviceName= "ChickWeightService"


@Injectable({
 providedIn: 'root',
})
export class ChickWeightService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<ChickWeightDto> {
   let returnObject = new ChickWeightDto()
   await this.RepositoryService.getItemAsync('ChickWeight/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<ChickWeightDto> {
   let returnObject = new ChickWeightDto()
   await this.RepositoryService.getItemAsync('ChickWeight/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_ChickWeight) => {
           returnObject = _ChickWeight;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_ChickWeightDto>
{
   let returnList = new PList_ChickWeightDto();
   await this.RepositoryService.getAllAsync('ChickWeight/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_ChickWeightDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_ChickWeightDto> {
       let returnList = new PList_ChickWeightDto();
     await  this.RepositoryService.getAllAsync('ChickWeight/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_ChickWeightDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_ChickWeightDto: ChickWeightDto): Promise<ChickWeightDto> {
           let returnvalue: ChickWeightDto = null;
           await this.RepositoryService.createAsync('ChickWeight/Create_Custom', _ChickWeightDto)
             .then((res) => {
                 returnvalue = res as ChickWeightDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_ChickWeightDto: ChickWeightDto): Promise<ChickWeightDto> {
               let returnvalue: ChickWeightDto = null;
               await this.RepositoryService.updateAsync('ChickWeight/Update_Custom', _ChickWeightDto)
                 .then((res) => {
                     returnvalue = res as ChickWeightDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('ChickWeight/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

 async Get_AllListFilter(_Filter_ChickWeightDto: Filter_ChickWeightDto, pagingString = ''): Promise<PList_ChickWeightDto> {
  let returnList = new PList_ChickWeightDto();
       await    this.RepositoryService.getAllExteraAsync('ChickWeight/Get_AllCustom/' + pagingString, _Filter_ChickWeightDto)
            .then((res) => {
                returnList.Data = res['value'] as List_ChickWeightDto[];
                returnList.TotalCount = res['@odata.count'] as number;
            }).catch (
      (error) => {
              this.errorService.Failed(error);
              return undefined;
          }
    );
          return returnList;
          }
}
