

export class AccFlockDto {
    GUID: string;
    FlockID: number;
    FlockGUID: string;
    Name: string;
    AccCode: number;

}


export class List_AccFlockDto {
    GUID: string;
    FlockGUID: string;
    FlockName: string;
    AccCode: number;

}

export class Combo_AccFlockDto {
    GUID: string;
    Name: string;
    AccCode:string;


}

export class PList_AccFlockDto {
Data: List_AccFlockDto[];
TotalCount: number;
}

export class Filter_FlockByFinalCostCycleOfficesAndSubOffices  {
    FinalCostCycleOfficeGUIDs: string[];
    OfficeGUID: string;
    SubOfficeGUIDs: string[];
    AccFlockGUIDs: string[];
    }
