

export class BreedStandardDto {
    GUID: string;
    BreedGUID: string;
    Name: string;

}


export class Combo_BreedStandardDto {
    GUID: string;
    BreedGUID: string;
    BreedName: string;
    Name: string;

}


export class List_BreedStandardDto {
    GUID: string;
    BreedGUID: string;
    BreedName: string;
    Name: string;

}
export class PList_BreedStandardDto {
Data: List_BreedStandardDto[];
TotalCount: number;
}
