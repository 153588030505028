

export class BreedStandardDetailDto {
    GUID: string;
    BreedStandardID: number;
    BreedStandardGUID: string;
    Weeks: number;
    MortalityHenPercent: number;
    MortalityRoosterPercent: number;
    WeightHen: number;
    WeightRooster: number;
    FeedHen: number;
    FeedRooster: number;
    EggWeight: number;
    EggPercentProduct: number;
    EggCountWeek: number;
    EggHachableCount: number;
    EggHachablePercent: number;
    HachPercent: number;
    ChickCount: number;
    EggDailyProduct: number;
    HHTotalEggWeight: number;
    CumMortalityHenPercent: number;
    ZaribTabdil: number;

}


export class Combo_BreedStandardDetailDto {
    GUID: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    Weeks: number;
    MortalityHenPercent: number;
    MortalityRoosterPercent: number;
    WeightHen: number;
    WeightRooster: number;
    FeedHen: number;
    FeedRooster: number;
    EggWeight: number;
    EggPercentProduct: number;
    EggCountWeek: number;
    EggHachableCount: number;
    EggHachablePercent: number;
    HachPercent: number;
    ChickCount: number;
    EggDailyProduct: number;
    HHTotalEggWeight: number;
    CumMortalityHenPercent: number;
    ZaribTabdil: number;

}


export class List_BreedStandardDetailDto {
    GUID: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    Weeks: number;
    MortalityHenPercent: number;
    MortalityRoosterPercent: number;
    WeightHen: number;
    WeightRooster: number;
    FeedHen: number;
    FeedRooster: number;
    EggWeight: number;
    EggPercentProduct: number;
    EggCountWeek: number;
    EggHachableCount: number;
    EggHachablePercent: number;
    HachPercent: number;
    ChickCount: number;
    EggDailyProduct: number;
    HHTotalEggWeight: number;
    CumMortalityHenPercent: number;
    ZaribTabdil: number;

}
export class PList_BreedStandardDetailDto {
Data: List_BreedStandardDetailDto[];
TotalCount: number;
}
