<div class="container-fluid">
    <div class="row">
        _CanUpload:{{_CanUpload}}/_CanDownload:{{_CanDownload}}**DocumentGUID:{{DocumentGUID}}/_CanDelete:{{_CanDelete}}/_PreviewURL:{{DownloadLink}}/FileErrorMessage{{FileErrorMessage}}
        <input style="display: none" type="file" (change)="onSelectFile($event);" #FileComponent>
        <button type="button" mat-raised-button *ngIf="_CanUpload && (DocumentGUID==null || DocumentGUID==='' || DocumentGUID==='00000000-0000-0000-0000-000000000000') "  (click)="FileComponent.click()">
                <span class="ShowInMiddleMonitor2">بارگزاری</span>  <span class="simple-icon-cloud-upload"></span>

                <i *ngIf="StartUploading">
                    <mat-spinner [diameter]="20" style="position: relative;float: left;margin-top: 8px;color: white"></mat-spinner>
                </i>
            </button>
            <div>
            <a [href]="DownloadLink" mat-raised-button  target="_blank" *ngIf="_CanDownload && (DocumentGUID!=null && DocumentGUID?.length>0 && DocumentGUID!=='00000000-0000-0000-0000-000000000000')" class="btn-info" style="font-family: iransansweb;">
               دریافت<span class="simple-icon-cloud-download" ></span>
            </a>
            <button type="button" mat-raised-button (click)="RemoveFile()" *ngIf="(DocumentGUID!=null && DocumentGUID?.length>0 && DocumentGUID!=='00000000-0000-0000-0000-000000000000') && _CanDelete " style="margin:4px ;"  class="btn-danger">
                <span class="simple-icon-close"></span>
            </button>
        </div>
        <span>{{FileErrorMessage}}</span>
    </div>
</div>