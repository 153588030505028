

export class CullingTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_CullingTypeDto {
    GUID: string;
    Name: string;

}


export class List_CullingTypeDto {
    GUID: string;
    Name: string;

}
export class PList_CullingTypeDto {
Data: List_CullingTypeDto[];
TotalCount: number;
}
