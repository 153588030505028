import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { Combo_FinalCostCycleOfficeDto, FinalCostCycleOfficeDto, List_FinalCostCycleOfficeDto, PList_FinalCostCycleOfficeDto } from './final-cost-cycle-office.model'



const serviceName = "FinalCostCycleOfficeService"


@Injectable({
  providedIn: 'root',
})
export class FinalCostCycleOfficeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<FinalCostCycleOfficeDto> {
    let returnObject = new FinalCostCycleOfficeDto()
    await this.RepositoryService.getItemAsync('FinalCostCycleOffice/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<FinalCostCycleOfficeDto> {
    let returnObject = new FinalCostCycleOfficeDto()
    await this.RepositoryService.getItemAsync('FinalCostCycleOffice/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_FinalCostCycleOffice) => {
          returnObject = _FinalCostCycleOffice;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_FinalCostCycleOfficeDto> {
    let returnList = new PList_FinalCostCycleOfficeDto();
    await this.RepositoryService.getAllAsync('FinalCostCycleOffice/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostCycleOfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(OfficeGUID: string, pagingString = ''): Promise<PList_FinalCostCycleOfficeDto> {
    let returnList = new PList_FinalCostCycleOfficeDto();
    await this.RepositoryService.getAllAsync('FinalCostCycleOffice/Get_All(OfficeGUID=' + OfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostCycleOfficeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_FinalCostCycleOfficeDto: FinalCostCycleOfficeDto): Promise<FinalCostCycleOfficeDto> {
    let returnvalue: FinalCostCycleOfficeDto = null;
    await this.RepositoryService.createAsync('FinalCostCycleOffice/Create_Custom', _FinalCostCycleOfficeDto)
      .then((res) => {
        returnvalue = res as FinalCostCycleOfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_FinalCostCycleOfficeDto: FinalCostCycleOfficeDto): Promise<FinalCostCycleOfficeDto> {
    let returnvalue: FinalCostCycleOfficeDto = null;
    await this.RepositoryService.updateAsync('FinalCostCycleOffice/Update_Custom', _FinalCostCycleOfficeDto)
      .then((res) => {
        returnvalue = res as FinalCostCycleOfficeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycleOffice/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  
  async Close(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycleOffice/Close_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Open(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycleOffice/Open_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async GetCombo_ByOffice(OfficeGUID: string): Promise<Combo_FinalCostCycleOfficeDto[]> {
    let returnvalue: Combo_FinalCostCycleOfficeDto[] = [];
    await this.RepositoryService.getAllAsync('FinalCostCycleOffice/GetCombo_ByOffice(OfficeGUID=' + OfficeGUID + ')')
      .then((res) => {
        returnvalue = res['value'] as Combo_FinalCostCycleOfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }


  async GetCombo_AllLastCycleByOffice(OfficeGUID: string , CurrentFinalCostCycleOfficeGUID : string): Promise<Combo_FinalCostCycleOfficeDto[]> {
    let returnvalue: Combo_FinalCostCycleOfficeDto[] = [];
    await this.RepositoryService.getAllAsync('FinalCostCycleOffice/GetCombo_AllLastCycleByOffice(OfficeGUID=' + OfficeGUID + ',CurrentFinalCostCycleOfficeGUID='+CurrentFinalCostCycleOfficeGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_FinalCostCycleOfficeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }


  async Get_Document(FinalCostCycleOfficeGUID: string , AccTitleGUID: string){
    let returnvalue: string;
    await this.RepositoryService.Download('FinalCostCycleOffice/Get_Document(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ',AccTitleGUID='+AccTitleGUID+')')
      .then((res) => {
        returnvalue = res['value'] as string;
      }).catch(
       (error) => {
           this.errorService.Failed(error);
           returnvalue = undefined;
         }
        );
    return returnvalue;
  }


  

  async Get_EmptyExcelFile(FinalCostCycleOfficeGUID: string ){
    let returnvalue: string;
    await this.RepositoryService.Download('FinalCostCycleOffice/Get_EmptyExcelFile(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID +')')
      .then((res) => {
        returnvalue = res['value'] as string;
      }).catch(
       (error) => {
           this.errorService.Failed(error);
           returnvalue = undefined;
         }
        );
    return returnvalue;
  }



  async UploadDocument(_Attachment: FormData): Promise<boolean> {
    let returnObject = false;
   await this.RepositoryService.UploadAsync('UploadDocument/UploadFinalCostCycleOfficeDocument', _Attachment)
      .then(
        (_document) => {
          returnObject = true
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          returnObject = undefined;
        }
      );
    return returnObject;
  }
  
  
  

  async Get_CalcFinalCost( GUID : string , CalcTypeID: number): Promise<boolean> {
    let returnvalue: boolean;
    returnvalue = false;
    await this.RepositoryService.getAllAsync('FinalCostCycleOffice/Get_CalcFinalCost(Key=' + GUID + ',CalcTypeID='+CalcTypeID+')')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }

}
