

export class BreedDto {
    GUID: string;
    FlockTypeID: number;
    FlockTypeGUID: string;
    Name: string;

}


export class Combo_BreedDto {
    GUID: string;
    FlockTypeGUID: string;
    FlockTypeName: string;
    Name: string;

}


export class List_BreedDto {
    GUID: string;
    FlockTypeGUID: string;
    FlockTypeName: string;
    Name: string;

}
export class PList_BreedDto {
Data: List_BreedDto[];
TotalCount: number;
}
