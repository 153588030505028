

export class ServiceCostAmountShareDetailDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    SLID: number;
    SLGUID: string;
    SDL4ID: number;
    SDL4GUID: string;
    SDL5ID: number;
    SDL5GUID: string;
    SDL6ID: number;
    SDL6GUID: string;
    DDL4ID: number;
    DDL4GUID: string;
    DDL5ID: number;
    DDL5GUID: string;
    DDL6ID: number;
    DDL6GUID: string;
    Amount: number;
    CurrentTimeSheetAmount: number;
    TotalSumTimeSheetAmount: number;

}


export class List_ServiceCostAmountShareDetailDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    SLGUID: string;
    SLName: string;
    SDL4GUID: string;
    SDL4Name: string;
    SDL5GUID: string;
    SDL5Name: string;
    SDL6GUID: string;
    SDL6Name: string;
    DDL4GUID: string;
    DDL4Name: string;
    DDL5GUID: string;
    DDL5Name: string;
    DDL6GUID: string;
    DDL6Name: string;
    Amount: number;
    CurrentTimeSheetAmount: number;
    TotalSumTimeSheetAmount: number;

}
export class PList_ServiceCostAmountShareDetailDto {
Data: List_ServiceCostAmountShareDetailDto[];
TotalCount: number;
}


export class FilterServiceCostAmountShareDetailDto{
    FinalCostCycleOfficeGUID: string;
    SubOfficeGUID: string;
    DL5GUID: string;
    DL6GUID: string;
    SLGUID: string;
    isSource: boolean;
}