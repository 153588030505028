

export class CullingDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    CullingTypeID: number;
    CullingTypeGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

}


export class List_CullingDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    CullingTypeGUID: string;
    CullingTypeName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

    
    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;
}
export class PList_CullingDto {
Data: List_CullingDto[];
TotalCount: number;
}



export class Save_CullingDto {
    Data: List_CullingDto[];
    GpFlockGUID: string;
    sData: string;
}