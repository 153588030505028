import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SubOfficeHouseModelDto, Combo_SubOfficeHouseModelDto, List_SubOfficeHouseModelDto, PList_SubOfficeHouseModelDto } from './sub-office-house-model.model'



const serviceName = "SubOfficeHouseModelService"


@Injectable({
  providedIn: 'root',
})
export class SubOfficeHouseModelService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SubOfficeHouseModelDto> {
    let returnObject = new SubOfficeHouseModelDto()
    await this.RepositoryService.getItemAsync('SubOfficeHouseModel/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SubOfficeHouseModelDto> {
    let returnObject = new SubOfficeHouseModelDto()
    await this.RepositoryService.getItemAsync('SubOfficeHouseModel/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_SubOfficeHouseModel) => {
          returnObject = _SubOfficeHouseModel;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_SubOfficeHouseModelDto> {
    let returnList = new PList_SubOfficeHouseModelDto();
    await this.RepositoryService.getAllAsync('SubOfficeHouseModel/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SubOfficeHouseModelDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_SubOfficeHouseModelDto> {
    let returnList = new PList_SubOfficeHouseModelDto();
    await this.RepositoryService.getAllAsync('SubOfficeHouseModel/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SubOfficeHouseModelDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_SubOfficeHouseModelDto: SubOfficeHouseModelDto): Promise<SubOfficeHouseModelDto> {
    let returnvalue: SubOfficeHouseModelDto = null;
    await this.RepositoryService.createAsync('SubOfficeHouseModel/Create_Custom', _SubOfficeHouseModelDto)
      .then((res) => {
        returnvalue = res as SubOfficeHouseModelDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_SubOfficeHouseModelDto: SubOfficeHouseModelDto): Promise<SubOfficeHouseModelDto> {
    let returnvalue: SubOfficeHouseModelDto = null;
    await this.RepositoryService.updateAsync('SubOfficeHouseModel/Update_Custom', _SubOfficeHouseModelDto)
      .then((res) => {
        returnvalue = res as SubOfficeHouseModelDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('SubOfficeHouseModel/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(SubOfficeGUID: string): Promise<Combo_SubOfficeHouseModelDto[]> {
    let returnvalue: Combo_SubOfficeHouseModelDto[] = [];
    await this.RepositoryService.getAllAsync('SubOfficeHouseModel/Get_Combo(SubOfficeGUID='+SubOfficeGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_SubOfficeHouseModelDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
