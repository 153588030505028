import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LangService } from 'src/app/shared/lang.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormMode } from 'src/app/_models';
import { UserProfileDto } from './user-profile.model';
import { UserProfileService } from './user-profile.service';

@Component({
  selector: 'cmp-user-profile-component',
  templateUrl: './user-profile.component.html',
})

export class UserProfileComponent implements OnInit {
  @Input() KeyGUID = environment.EmptyGUID;
  @Output() onCancel = new EventEmitter<string>();
  @Output() onSaveCompleted = new EventEmitter<string>();
  SpinStatus = false;
  UserProfileValue:UserProfileDto=new UserProfileDto();
  LoadCompleted=false;
  SubOfficeNameLists:string[]=[];
  UserRoleNameLists:string[]=[];
  OldPassword: string='';
  NewPassword:string='';
  NewPasswordReEnter:string='';
  private ComponentName: string = '';
  //#endregion
  myForm: UntypedFormGroup = this.fb.group({
    GUID: [],
    Name: ['', [Validators.required, Validators.maxLength(150)]],
  })

  constructor(
    protected service: UserProfileService,
    protected fb: UntypedFormBuilder,
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected toastr: ToastrService,
    protected langService: LangService,
  ) {

  }

  //#region variables
  StartSaving = false;
  CauseTestSave = false;
  CauseTestUpdateSave = false;

  //#endregion

  ngOnInit(): void {

  }

  async submitFormData() {
    let FormData = this.FillUserProfile();

    this.SpinStatus = true;
    let _UserProfile: UserProfileDto = null;
    
      _UserProfile = await this.service.Update_Custom(FormData);
    
    if (_UserProfile) {
      this.FillForm(_UserProfile);
      this.onSaveCompleted.emit('');
      this.myForm.markAsPristine();
      this.SpinStatus = false;
    }
    this.SpinStatus = false;
  }

  public async LoadFormData() {
    console.log('Call LoadFormData()')
    this.SpinStatus = true;
    const serviceValue = await this.service.Get_Item();
    if (serviceValue) {
      this.UserProfileValue=serviceValue;
      this.SubOfficeNameLists=serviceValue.SubOfficeNames.split(',');
      this.UserRoleNameLists=serviceValue.RoleNames.split(',');
      this.LoadCompleted=true;
      this.FillForm(serviceValue);
      this.SpinStatus = false;
    }
    
      
  }

  //#region onchange
  FillUserProfile(): UserProfileDto {
    const _UserProfileDto: UserProfileDto = new UserProfileDto();
    _UserProfileDto.GUID = this.myForm.value.GUID;
    _UserProfileDto.Name = this.myForm.value.Name;
    return _UserProfileDto;
  }

  async FillForm(_UserProfile: UserProfileDto) {
    this.KeyGUID = _UserProfile.GUID;
    this.myForm.controls.GUID.setValue(_UserProfile.GUID);
    this.myForm.controls.Name.setValue(_UserProfile.Name);
  }

  //##endregion
  ngAfterViewInit(): void {

  }
  async LoadCombo() {
  }

  NewPassowrdReEnterLostFocus(){

  }
  NewPassowrdLostFocus(){

  }
  async DoChangePassword(){
    if(this.OldPassword.trim().length==0 || this.NewPassword.trim().length==0 || this.NewPassword!=this.NewPasswordReEnter){
return;
    }
    const serviceValue = await this.service.UserChangePassword(this.OldPassword,this.NewPassword);
    if (serviceValue) {

    }

  }
}
