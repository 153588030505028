import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { ServiceCostAmountShareDetailDto, List_ServiceCostAmountShareDetailDto, PList_ServiceCostAmountShareDetailDto, FilterServiceCostAmountShareDetailDto } from './service-cost-amount-share-detail.model'
import { MessageService } from 'src/app/shared/message.service';



const serviceName = "ServiceCostAmountShareDetailService"


@Injectable({
  providedIn: 'root',
})
export class ServiceCostAmountShareDetailService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected messageService: MessageService,

    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<ServiceCostAmountShareDetailDto> {
    let returnObject = new ServiceCostAmountShareDetailDto()
    await this.RepositoryService.getItemAsync('ServiceCostAmountShareDetail/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<ServiceCostAmountShareDetailDto> {
    let returnObject = new ServiceCostAmountShareDetailDto()
    await this.RepositoryService.getItemAsync('ServiceCostAmountShareDetail/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_ServiceCostAmountShareDetail) => {
          returnObject = _ServiceCostAmountShareDetail;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_ServiceCostAmountShareDetailDto> {
    let returnList = new PList_ServiceCostAmountShareDetailDto();
    await this.RepositoryService.getAllAsync('ServiceCostAmountShareDetail/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ServiceCostAmountShareDetailDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  
  async Get_AllDetailCustom(_FilterServiceCostAmountShareDetailDto: FilterServiceCostAmountShareDetailDto , pagingString = ''): Promise<PList_ServiceCostAmountShareDetailDto> {
    let returnList = new PList_ServiceCostAmountShareDetailDto();
    await this.RepositoryService.getAllExteraAsync('ServiceCostAmountShareDetail/Get_AllDetailCustom/' + pagingString , _FilterServiceCostAmountShareDetailDto)
      .then((res) => {
        returnList.Data = res['value'] as List_ServiceCostAmountShareDetailDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


  async Get_AllList(pagingString = ''): Promise<PList_ServiceCostAmountShareDetailDto> {
    let returnList = new PList_ServiceCostAmountShareDetailDto();
    await this.RepositoryService.getAllAsync('ServiceCostAmountShareDetail/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ServiceCostAmountShareDetailDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_ServiceCostAmountShareDetailDto: ServiceCostAmountShareDetailDto): Promise<ServiceCostAmountShareDetailDto> {
    let returnvalue: ServiceCostAmountShareDetailDto = null;
    await this.RepositoryService.createAsync('ServiceCostAmountShareDetail/Create_Custom', _ServiceCostAmountShareDetailDto)
      .then((res) => {
        returnvalue = res as ServiceCostAmountShareDetailDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_ServiceCostAmountShareDetailDto: ServiceCostAmountShareDetailDto): Promise<ServiceCostAmountShareDetailDto> {
    let returnvalue: ServiceCostAmountShareDetailDto = null;
    await this.RepositoryService.updateAsync('ServiceCostAmountShareDetail/Update_Custom', _ServiceCostAmountShareDetailDto)
      .then((res) => {
        returnvalue = res as ServiceCostAmountShareDetailDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('ServiceCostAmountShareDetail/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Calc_ServiceCostAmountShareDetail(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<string[]> {
    let returnList: string[];
    await this.RepositoryService.getItemAsync('ServiceCostAmountShareDetail/Calc_ServiceCostAmountShareDetail(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res['value'] as string[];

        let toShow = returnList[0];

        if (returnList.length > 1) {
          returnList.shift();
          toShow += ": <br/>";
          toShow += '<ul>';
          returnList.forEach(element => {
            toShow += '<li>';
            toShow += element;
            toShow += '</li>';
          });
          toShow += '</ul>';
        }
        if (returnList.length == 1) {
          this.messageService.SuccessBox(toShow);
        } else {
          this.messageService.ErrorBox(toShow);
        }
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }
}
