

export class PartMasterGroupDto {
    GUID: string;
    Name: string;
    Code: number;
    TopLevelNumber: number;
    RangeFrom: number;
    RangeTo: number;
}


export class Combo_PartMasterGroupDto {
    GUID: string;
    Name: string;
    Code: number;
    TopLevelNumber: number;
    RangeFrom: number;
    RangeTo: number;
}


export class List_PartMasterGroupDto {
    GUID: string;
    Name: string;
    Code: number;
    TopLevelNumber: number;
    RangeFrom: number;
    RangeTo: number;
}
export class PList_PartMasterGroupDto {
Data: List_PartMasterGroupDto[];
TotalCount: number;
}
