import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { Combo_GpFlockDto, GpFlockDto, List_GpFlockDto, PList_GpFlockDto } from './gp-flock.model'



const serviceName = "GpFlockService"


@Injectable({
  providedIn: 'root',
})
export class GpFlockService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<GpFlockDto> {
    let returnObject = new GpFlockDto()
    await this.RepositoryService.getItemAsync('GpFlock/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<GpFlockDto> {
    let returnObject = new GpFlockDto()
    await this.RepositoryService.getItemAsync('GpFlock/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_GpFlock) => {
          returnObject = _GpFlock;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_GpFlockDto> {
    let returnList = new PList_GpFlockDto();
    await this.RepositoryService.getAllAsync('GpFlock/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_GpFlockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(SubOfficeGUID:string , pagingString = ''): Promise<PList_GpFlockDto> {
    let returnList = new PList_GpFlockDto();
    await this.RepositoryService.getAllAsync('GpFlock/Get_All(SubOfficeGUID='+SubOfficeGUID+')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_GpFlockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_GpFlockDto: GpFlockDto): Promise<GpFlockDto> {
    let returnvalue: GpFlockDto = null;
    await this.RepositoryService.createAsync('GpFlock/Create_Custom', _GpFlockDto)
      .then((res) => {
        returnvalue = res as GpFlockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_GpFlockDto: GpFlockDto): Promise<GpFlockDto> {
    let returnvalue: GpFlockDto = null;
    await this.RepositoryService.updateAsync('GpFlock/Update_Custom', _GpFlockDto)
      .then((res) => {
        returnvalue = res as GpFlockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('GpFlock/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo_BySubOffice(SubOfficeGUID: string): Promise<Combo_GpFlockDto[]> {
    let returnvalue: Combo_GpFlockDto[] = [];
            await this.RepositoryService.getAllAsync('GpFlock/LoadCombo_BySubOffice(SubOfficeGUID='+SubOfficeGUID+')')
              .then((res) => {
                  returnvalue = res['value'] as Combo_GpFlockDto[];
              }).catch (
        (error) => {
                this.errorService.Failed(error);
            });
            return returnvalue;
            }
}
