

export class RationGroupDetailDto {
    GUID: string;
    RationGroupID: number;
    RationGroupGUID: string;
    RationTypeID: number;
    RationTypeGUID: string;
    StartWeek: number;
    EndWeek: number;

}


export class Combo_RationGroupDetailDto {
    GUID: string;
    RationGroupGUID: string;
    RationGroupName: string;
    RationTypeGUID: string;
    RationTypeName: string;
    StartWeek: number;
    EndWeek: number;

}


export class List_RationGroupDetailDto {
    GUID: string;
    RationGroupGUID: string;
    RationGroupName: string;
    RationTypeGUID: string;
    RationTypeName: string;
    StartWeek: number;
    EndWeek: number;

}
export class PList_RationGroupDetailDto {
Data: List_RationGroupDetailDto[];
TotalCount: number;
}
