import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const languageKey = '__lang';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  isSingleLang = false;
  renderer: Renderer2;
  supportedLanguages: Language[] = [
    { code: 'zhCN', direction: 'rtl', label: 'فارسی', shorthand: 'فا' },
    { code: 'en', direction: 'ltr', label: 'English', shorthand: 'en' }
    
  ];

  constructor(
    private translate: TranslateService,
    private rendererFactory: RendererFactory2,
    private router: Router
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    const cLn = String(localStorage.getItem('cLanguage'));

    if (cLn !== null && cLn !== 'null' && cLn.length > 0) {
      translate.use(cLn);
    } else {
      const cLang = environment.defaultLang;
      translate.setDefaultLang(cLang);
      localStorage.setItem('cLanguage',cLang);
      translate.use(cLang);
    }


  }

  init(): void {
    this.translate.use(environment.defaultLang);
  }


  get language(): string {
    return this.translate.currentLang;
  }

  ChangeLanguage(cLang: string): void{
    this.translate.setDefaultLang(cLang);
    localStorage.setItem('cLanguage',cLang);
    this.translate.use(cLang);
  }


  TranslateKey(Key: string): string{
    return this.translate.instant(Key)
  }
}

export class Language {
  code: string;
  direction: string;
  label: string;
  shorthand: string;
}
