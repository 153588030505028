

export class DLTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_DLTypeDto {
    GUID: string;
    Name: string;

}


export class List_DLTypeDto {
    GUID: string;
    Name: string;

}
export class PList_DLTypeDto {
Data: List_DLTypeDto[];
TotalCount: number;
}
