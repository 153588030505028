

export class FinalCostCycleDto {
    GUID: string;
    Name: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;
    isClose: boolean;
}

export class List_FinalCostCycleDto {
    GUID: string;
    Name: string;
    StartDate: string;
    StartDateShamsi: string;
    EndDate: string;
    EndDateShamsi: string;
    isClose: boolean;
}

export class PList_FinalCostCycleDto {
    Data: List_FinalCostCycleDto[];
    TotalCount: number;
}


export class Combo_FinalCostCycleDto {
    GUID: string;
    Name: string;
    StartDateShamsi: string;
    EndDateShamsi: string;
    isClose: boolean;
}
