

export class SoftwareDto {
    GUID: string;
    Name: string;
    IconName: string;
    BackgroundColor: string;
    Color: string;
    URLAddress: string;
}


export class Combo_SoftwareDto {
    GUID: string;
    Name: string;

}


export class List_SoftwareDto {
    GUID: string;
    Name: string;
    IconName: string;
    BackgroundColor: string;
    Color    : string;
    URLAddress: string;

}
export class PList_SoftwareDto {
Data: List_SoftwareDto[];
TotalCount: number;

}
