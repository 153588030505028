import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { StockDto, Combo_StockDto, List_StockDto, PList_StockDto } from './stock.model'



const serviceName = "StockService"


@Injectable({
  providedIn: 'root',
})
export class StockService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<StockDto> {
    let returnObject = new StockDto()
    await this.RepositoryService.getItemAsync('Stock/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<StockDto> {
    let returnObject = new StockDto()
    await this.RepositoryService.getItemAsync('Stock/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Stock) => {
          returnObject = _Stock;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_StockDto> {
    let returnList = new PList_StockDto();
    await this.RepositoryService.getAllAsync('Stock/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(OfficeGUID: string, pagingString = ''): Promise<PList_StockDto> {
    let returnList = new PList_StockDto();
    await this.RepositoryService.getAllAsync('Stock/Get_All(OfficeGUID=' + OfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_StockDto: StockDto): Promise<StockDto> {
    let returnvalue: StockDto = null;
    await this.RepositoryService.createAsync('Stock/Create_Custom', _StockDto)
      .then((res) => {
        returnvalue = res as StockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_StockDto: StockDto): Promise<StockDto> {
    let returnvalue: StockDto = null;
    await this.RepositoryService.updateAsync('Stock/Update_Custom', _StockDto)
      .then((res) => {
        returnvalue = res as StockDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Stock/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_StockDto[]> {
    let returnvalue: Combo_StockDto[] = [];
    await this.RepositoryService.getAllAsync('Stock/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_StockDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async SyncStock(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('Stock/SyncStock/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async LoadCombo_ByOffice(OfficeGUID:string ): Promise<Combo_StockDto[]> {
    let returnvalue: Combo_StockDto[] = [];
    await this.RepositoryService.getAllAsync('Stock/LoadCombo_ByOffice(OfficeGUID='+OfficeGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_StockDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue =undefined;
        });
    return returnvalue;
  }

}
