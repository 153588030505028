

export class MonthDto {
    GUID: string;
    Name: string;
    Row: number;

}


export class Combo_MonthDto {
    GUID: string;
    Name: string;
    Row: number;

}


export class List_MonthDto {
    GUID: string;
    Name: string;
    Row: number;

}
export class PList_MonthDto {
Data: List_MonthDto[];
TotalCount: number;
}
