import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { DLDto, Combo_DLDto, List_DLDto, PList_DLDto } from './dl.model'



const serviceName = "DLService"


@Injectable({
  providedIn: 'root',
})
export class DLService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<DLDto> {
    let returnObject = new DLDto()
    await this.RepositoryService.getItemAsync('DL/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<DLDto> {
    let returnObject = new DLDto()
    await this.RepositoryService.getItemAsync('DL/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_DL) => {
          returnObject = _DL;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_DLDto> {
    let returnList = new PList_DLDto();
    await this.RepositoryService.getAllAsync('DL/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_DLDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(OfficeGUID: string , pagingString = ''): Promise<PList_DLDto> {
    let returnList = new PList_DLDto();
    await this.RepositoryService.getAllAsync('DL/Get_All(OfficeGUID='+OfficeGUID+')' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_DLDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_DLDto: DLDto): Promise<DLDto> {
    let returnvalue: DLDto = null;
    await this.RepositoryService.createAsync('DL/Create_Custom', _DLDto)
      .then((res) => {
        returnvalue = res as DLDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_DLDto: DLDto): Promise<DLDto> {
    let returnvalue: DLDto = null;
    await this.RepositoryService.updateAsync('DL/Update_Custom', _DLDto)
      .then((res) => {
        returnvalue = res as DLDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('DL/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  
  async AddInSeyagh(GUID: string): Promise<number> {
    let returnvalue: number = 0;
    await this.RepositoryService.getAsync('DL/AddInSeyagh_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_DLDto[]> {
    let returnvalue: Combo_DLDto[] = [];
    await this.RepositoryService.getAllAsync('DL/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_DLDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue =undefined;
        });
    return returnvalue;
  }


  async LoadCombo_ByDLType(DLTypeGUID: string): Promise<Combo_DLDto[]> {
    let returnvalue: Combo_DLDto[] = [];
    await this.RepositoryService.getAllAsync('DL/LoadCombo_ByDLType(DLTypeGUID='+DLTypeGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_DLDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue =undefined;
        });
    return returnvalue;
  }


  async LoadCombo_ByDLTypeOffice(OfficeGUID:string ,DLTypeGUID: string): Promise<Combo_DLDto[]> {
    let returnvalue: Combo_DLDto[] = [];
    await this.RepositoryService.getAllAsync('DL/LoadCombo_ByDLTypeOffice(OfficeGUID='+OfficeGUID+',DLTypeGUID='+DLTypeGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_DLDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue =undefined;
        });
    return returnvalue;
  }



  async LoadCombo_serviceDL(): Promise<Combo_DLDto[]> {
    let returnvalue: Combo_DLDto[] = [];
    await this.RepositoryService.getAllAsync('DL/LoadCombo_serviceDL')
      .then((res) => {
        returnvalue = res['value'] as Combo_DLDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async SyncDL(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('DL/SyncDL/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
