import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { StockInvoiceTypeDto, Combo_StockInvoiceTypeDto, List_StockInvoiceTypeDto, PList_StockInvoiceTypeDto } from './stock-invoice-type.model'



const serviceName = "StockInvoiceTypeService"


@Injectable({
  providedIn: 'root',
})
export class StockInvoiceTypeService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<StockInvoiceTypeDto> {
    let returnObject = new StockInvoiceTypeDto()
    await this.RepositoryService.getItemAsync('StockInvoiceType/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<StockInvoiceTypeDto> {
    let returnObject = new StockInvoiceTypeDto()
    await this.RepositoryService.getItemAsync('StockInvoiceType/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_StockInvoiceType) => {
          returnObject = _StockInvoiceType;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_StockInvoiceTypeDto> {
    let returnList = new PList_StockInvoiceTypeDto();
    await this.RepositoryService.getAllAsync('StockInvoiceType/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockInvoiceTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_StockInvoiceTypeDto> {
    let returnList = new PList_StockInvoiceTypeDto();
    await this.RepositoryService.getAllAsync('StockInvoiceType/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_StockInvoiceTypeDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_StockInvoiceTypeDto: StockInvoiceTypeDto): Promise<StockInvoiceTypeDto> {
    let returnvalue: StockInvoiceTypeDto = null;
    await this.RepositoryService.createAsync('StockInvoiceType/Create_Custom', _StockInvoiceTypeDto)
      .then((res) => {
        returnvalue = res as StockInvoiceTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_StockInvoiceTypeDto: StockInvoiceTypeDto): Promise<StockInvoiceTypeDto> {
    let returnvalue: StockInvoiceTypeDto = null;
    await this.RepositoryService.updateAsync('StockInvoiceType/Update_Custom', _StockInvoiceTypeDto)
      .then((res) => {
        returnvalue = res as StockInvoiceTypeDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('StockInvoiceType/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo(): Promise<Combo_StockInvoiceTypeDto[]> {
    let returnvalue: Combo_StockInvoiceTypeDto[] = [];
    await this.RepositoryService.getAllAsync('StockInvoiceType/Get_Combo')
      .then((res) => {
        returnvalue = res['value'] as Combo_StockInvoiceTypeDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async SyncStockInvoiceType(): Promise<boolean> {
    let returnvalue = false;
    await this.RepositoryService.getAllAsync('StockInvoiceType/SyncStockInvoiceType/')
      .then((res) => {
        returnvalue = res['value'] as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
