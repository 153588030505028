import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../shared/error.service';
import { Injectable } from '@angular/core';


import { UserProfileDto } from './user-profile.model';
import { environment } from 'src/environments/environment';



const serviceName = "UserProfileService"


@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    constructor(
        protected RepositoryService: RepositoryService,
        protected toastr: ToastrService,
        protected langService: LangService,
        protected errorService: ErrorService,
        protected generalService: GeneralService
    ) {
    }


    ngOnInit(): void {

    }

    async Get_Item(): Promise<UserProfileDto> {
        let returnObject = new UserProfileDto()
        await this.RepositoryService.getItemAsync('SeamorghUser/Get_UserProfile')
            .then(
                (res) => {
                    returnObject = res;
                }
            ).catch(
                (error) => {
                    this.errorService.Failed(error);
                    return undefined;
                });
        return returnObject;
    }


    async UserChangePassword(OldPassworrd = '', NewPassowrd =''): Promise<UserProfileDto> {
        let returnObject = new UserProfileDto()
        await this.RepositoryService.getItemAsync('SeamorghUser/UserChangePassowrd(OldPassword=\'' + OldPassworrd + '\',NewPassword=\''+NewPassowrd+'\')')
            .then(
                (_CauseTest) => {
                    returnObject = _CauseTest;
                }
            ).catch(
                (error) => {
                    this.errorService.Failed(error);
                    return undefined;
                }
            );
        return returnObject;
    }





    async Create_Custom(_UserProfileDto: UserProfileDto): Promise<UserProfileDto> {
        let returnvalue: UserProfileDto = null;
        await this.RepositoryService.createAsync('CauseTest/Create_Custom', _UserProfileDto)
            .then((res) => {
                returnvalue = res as UserProfileDto;
            }).catch(
                (error) => {
                    this.errorService.Failed(error);
                });
        return returnvalue;
    }


    async Update_Custom(_UserProfileDto: UserProfileDto): Promise<UserProfileDto> {
        let returnvalue: UserProfileDto = null;
        await this.RepositoryService.updateAsync('CauseTest/Update_Custom', _UserProfileDto)
            .then((res) => {
                returnvalue = res as UserProfileDto;
            }).catch(
                (error) => {
                    this.errorService.Failed(error);
                });
        return returnvalue;
    }

}
