

export class WaterDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    sDateShamsi: string;
    sDate: string;
    Liter: number;
    Meter: number;
    Supplement: string;
    Description: string;
    isLock: boolean;

}


export class List_WaterDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    sDateShamsi: string;
    sDate: string;
    Liter: number;
    Meter: number;
    Supplement: string;
    Description: string;
    isLock: boolean;

    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    HenStandardValue: number;
    RosterStandardValue: number;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;

}

export class PList_WaterDto {
Data: List_WaterDto[];
TotalCount: number;
}


export class Save_WaterDto {
    Data: List_WaterDto[];
    GpFlockGUID: string;
    sData: string;
}