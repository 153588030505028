

export class FlockTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_FlockTypeDto {
    GUID: string;
    Name: string;

}


export class List_FlockTypeDto {
    GUID: string;
    Name: string;

}
export class PList_FlockTypeDto {
Data: List_FlockTypeDto[];
TotalCount: number;
}
