

export class DiseaseDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    DiseaseTypeID: number;
    DiseaseTypeGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

}


export class List_DiseaseDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    DiseaseTypeGUID: string;
    DiseaseTypeName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    Description: string;
    isLock: boolean;

    
    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    HenStandardValue: number;
    RosterStandardValue: number;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;

}
export class PList_DiseaseDto {
Data: List_DiseaseDto[];
TotalCount: number;
}


export class Save_DiseaseDto {
    Data: List_DiseaseDto[];
    GpFlockGUID: string;
    sData: string;
}