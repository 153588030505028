import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormMode } from 'src/app/_models';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
  ) { }
  myForm: UntypedFormGroup;
  myFormMode: FormMode = FormMode.ReadOnly;
  ngOnInit(): void {
    this.myForm = this.fb.group({
      GUID: new UntypedFormControl(),
      Name: new UntypedFormControl('', [Validators.required]),
      AlphabeticCode: new UntypedFormControl(),
Symbol: new UntypedFormControl(),
    });
  }
  SaveData(){}
  quickTransfer = [
        {
          name: "Samuel",
          username: "@sam224",
          image: "assets/images/contacts/1.jpg",
        },
        {
          name: "Cindy",
          username: "@cindyss",
          image: "assets/images/contacts/2.jpg",
        },
        {
          name: "David",
          username: "@davidxc",
          image: "assets/images/contacts/3.jpg",
        },
        {
          name: "Martha",
          username: "@marthaa",
          image: "assets/images/contacts/4.jpg",
        },
        {
          name: "Olivia",
          username: "@oliv62",
          image: "assets/images/contacts/5.jpg",
        },
  ];

}
