

export class FinalCostCycleOfficeDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    FinalCostCycleID: number;
    FinalCostCycleGUID: string;
    Name: string;
    isClose: boolean;
    StartSanadNumber: number;
    EndSanadNumber: number;
    BeforCloseStartSanadNumber: number;
    BeforCloseEndSanadNumber: number;
    LastFinalCostCycleOfficeGUID: number;
    StocktakingDate: string;
    LastStocktakingDate: string;
    SubOfficeGUIDs:string[];
    SanadType1: boolean;
    SanadType2: boolean;
    SanadType3: boolean;
    SanadType4: boolean;
    LoadAccAmount: boolean;
    CalcServiceAmount: boolean;
    LoadStockAmount: boolean;
    LoadGPAmount: boolean;
    isMasterCycle: boolean;
    LoadStockCount: boolean;

}


export class List_FinalCostCycleOfficeDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    FinalCostCycleGUID: string;
    FinalCostCycleName: string;
    Name: string;
    isClose: boolean;
    FinalCostCycleisClose: boolean;
    StartDateShamsi: string;
    EndDateShamsi: string;
    StartSanadNumber: number;
    EndSanadNumber: number;
    BeforCloseStartSanadNumber: number;
    BeforCloseEndSanadNumber: number;
    LastFinalCostCycleOfficeName: string;
    SanadType1: boolean;
    SanadType2: boolean;
    SanadType3: boolean;
    SanadType4: boolean;
    LoadAccAmount: boolean;
    CalcServiceAmount: boolean;
    LoadStockAmount: boolean;
    LoadGPAmount: boolean;
    isMasterCycle: boolean;
    LoadStockCount: boolean;

}
export class PList_FinalCostCycleOfficeDto {
    Data: List_FinalCostCycleOfficeDto[];
    TotalCount: number;
}


export class Combo_FinalCostCycleOfficeDto {
    GUID: string;
    Name: string;
}


