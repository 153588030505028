import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { ComponentDto, Combo_ComponentDto, List_ComponentDto, PList_ComponentDto } from './component.model'



const serviceName = "ComponentService"


@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<ComponentDto> {
    let returnObject = new ComponentDto()
    await this.RepositoryService.getItemAsync('Component/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<ComponentDto> {
    let returnObject = new ComponentDto()
    await this.RepositoryService.getItemAsync('Component/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Component) => {
          returnObject = _Component;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_ComponentDto> {
    let returnList = new PList_ComponentDto();
    await this.RepositoryService.getAllAsync('Component/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ComponentDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_ComponentDto> {
    let returnList = new PList_ComponentDto();
    await this.RepositoryService.getAllAsync('Component/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_ComponentDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_ComponentDto: ComponentDto): Promise<ComponentDto> {
    let returnvalue: ComponentDto = null;
    await this.RepositoryService.createAsync('Component/Create_Custom', _ComponentDto)
      .then((res) => {
        returnvalue = res as ComponentDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_ComponentDto: ComponentDto): Promise<ComponentDto> {
    let returnvalue: ComponentDto = null;
    await this.RepositoryService.updateAsync('Component/Update_Custom', _ComponentDto)
      .then((res) => {
        returnvalue = res as ComponentDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Component/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async LoadCombo_BySoftwareGUID(SoftwareGUID: string): Promise<Combo_ComponentDto[]> {
    let returnvalue: Combo_ComponentDto[] = [];
    await this.RepositoryService.getAllAsync('Component/LoadCombo_BySoftwareGUID(SoftwareGUID='+SoftwareGUID+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_ComponentDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
