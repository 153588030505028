

export class LifeCycleTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_LifeCycleTypeDto {
    GUID: string;
    Name: string;

}


export class List_LifeCycleTypeDto {
    GUID: string;
    Name: string;

}
export class PList_LifeCycleTypeDto {
Data: List_LifeCycleTypeDto[];
TotalCount: number;
}
