export class MatrialTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_MatrialTypeDto {
    GUID: string;
    Name: string;

}


export class List_MatrialTypeDto {
    GUID: string;
    Name: string;

}
export class PList_MatrialTypeDto {
Data: List_MatrialTypeDto[];
TotalCount: number;
}
