

export class RationGroupDetailFormulaDto {
    GUID: string;
    RationGroupDetailID: number;
    RationGroupDetailGUID: string;
    MatrialID: number;
    MatrialGUID: string;
    PercentValue: number;

}


export class Combo_RationGroupDetailFormulaDto {
    GUID: string;
    RationGroupDetailGUID: string;
    RationGroupDetailName: string;
    MatrialGUID: string;
    MatrialName: string;
    PercentValue: number;

}


export class List_RationGroupDetailFormulaDto {
    GUID: string;
    RationGroupDetailGUID: string;
    RationGroupDetailName: string;
    MatrialGUID: string;
    MatrialName: string;
    PercentValue: number;

}
export class PList_RationGroupDetailFormulaDto {
Data: List_RationGroupDetailFormulaDto[];
TotalCount: number;
}
