

export class FinalCostFlockDetailDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    SLID: number;
    SLGUID: string;
    DL4ID: number;
    DL4GUID: string;
    DL5ID: number;
    DL5GUID: string;
    FinalCostFlockID: number;
    FinalCostFlockGUID: string;
    Amount: number;
    ServiceAmount: number;

}


export class List_FinalCostFlockDetailDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
   FromDateShamsi: string;
    ToDateShamsi: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    FlockName: string;
    HouseName: string;
    Year: number;
    MonthID: number;
    MortalityCount: number;
    CullingCount: number;
    HouseStartWeek: number;
    HouseEndWeek: number;
    RationAmount: number;
    FirstHenCount: number;
    FirstRoosterCount: number;
    EndHenCount: number;
    EndRoosterCount: number;
    EndLifeCycleHenCount: number;
    EndLifeCycleRoosterCount: number;
    EggStatus1Count: number;
    EggStatus1Amount: number;
    EggStatus2Count: number;
    EggStatus2Amount: number;
    EggStatus3Count: number;
    EggStatus3Amount: number;
    EggStatus4Count: number;
    EggStatus4Amount: number;
    EggStatus5Count: number;
    EggStatus5Amount: number;

    NewEntryHenCount: number;
    NewEntryRoosterCount: number;
    InEntryHenCount: number;
    InEntryRoosterCount: number;
    OutEntryHenCount: number;
    OutEntryRoosterCount: number;
}

export class PList_FinalCostFlockDetailDto {
    Data: List_FinalCostFlockDetailDto[];
    TotalCount: number;
}


export class FilterFinalCostFlockDetailDto {
    OfficeGUID: string;
    FinalCostCycleOfficeGUIDs: string[];
    SubOfficeGUIDs: string[];
    AccFlockGUIDs: string[];
}


