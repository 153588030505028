

export class FactoryDto {
    GUID: string;
    Name: string;
    OfficeID: number;
    OfficeGUID: string;

}


export class Combo_FactoryDto {
    GUID: string;
    Name: string;
    OfficeGUID: string;
    OfficeName: string;

}


export class List_FactoryDto {
    GUID: string;
    Name: string;
    OfficeGUID: string;
    OfficeName: string;

}
export class PList_FactoryDto {
Data: List_FactoryDto[];
TotalCount: number;
}
