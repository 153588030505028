

export class DrugDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    GpPartID: number;
    GpPartGUID: string;
    UseMethodID: number;
    UseMethodGUID: string;
    sDateShamsi: string;
    sDate: string;
    Dosage: number;
    Batch: string;
    FullName: string;
    ExpireDateShamsi: string;
    ExpireDate: string;
    Diagnosed: string;
    Description: string;
    AccStockNumber: number;
    isLock: boolean;

}


export class List_DrugDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    GpPartGUID: string;
    GpPartName: string;
    UseMethodGUID: string;
    UseMethodName: string;
    sDateShamsi: string;
    sDate: string;
    Dosage: number;
    Batch: string;
    FullName: string;
    ExpireDateShamsi: string;
    ExpireDate: string;
    Diagnosed: string;
    Description: string;
    AccStockNumber: number;
    isLock: boolean;

    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;


    MaxDosage: number;
    GpFlockInventoryGUID: string;
}

export class PList_DrugDto {
Data: List_DrugDto[];
TotalCount: number;
}


export class Save_DrugDto {
    Data: List_DrugDto[];
    GpFlockGUID: string;
    sData: string;
}