import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { DiseaseTypeDto, Combo_DiseaseTypeDto, List_DiseaseTypeDto, PList_DiseaseTypeDto} from './disease-type.model'



const serviceName= "DiseaseTypeService"


@Injectable({
 providedIn: 'root',
})
export class DiseaseTypeService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }


ngOnInit(): void {


}

async Get_Item(GUID = ''): Promise<DiseaseTypeDto> {
   let returnObject = new DiseaseTypeDto()
   await this.RepositoryService.getItemAsync('DiseaseType/Get_Item(key='+ GUID + ')')
     .then(
       (res) => {
         returnObject = res;
       }
     ).catch (
      (error) => {
          this.errorService.Failed(error);
          return undefined;
      });
  return returnObject;
       }


async Get_ItemCustom(GUID = '', AutoEmit = true) : Promise<DiseaseTypeDto> {
   let returnObject = new DiseaseTypeDto()
   await this.RepositoryService.getItemAsync('DiseaseType/Get_ItemCustom(key='+ GUID + ')')
     .then(
       (_DiseaseType) => {
           returnObject = _DiseaseType;
       }
     ).catch(
       (error) => {
         this.errorService.Failed(error);
         return undefined;
       }
     );
   return returnObject;
 }


async Get_AllCustom(pagingString = ''): Promise<PList_DiseaseTypeDto>
{
   let returnList = new PList_DiseaseTypeDto();
   await this.RepositoryService.getAllAsync('DiseaseType/Get_AllCustom/' + pagingString)
     .then((res) => {
         returnList.Data = res['value'] as List_DiseaseTypeDto[];
         returnList.TotalCount = res['@odata.count'] as number;
     }).catch (
       (error) => {
       this.errorService.Failed(error);
       return undefined;
   }
     );
   return returnList;
   }



 async  Get_AllList(pagingString = ''): Promise<PList_DiseaseTypeDto> {
       let returnList = new PList_DiseaseTypeDto();
     await  this.RepositoryService.getAllAsync('DiseaseType/Get_All/' + pagingString)
         .then((res) => {
             returnList.Data = res['value'] as List_DiseaseTypeDto[];
             returnList.TotalCount = res['@odata.count'] as number;
         }).catch (
       (error) => {
           this.errorService.Failed(error);
           return undefined;
       }
     );
       return returnList;
       }



 async Create_Custom(_DiseaseTypeDto: DiseaseTypeDto): Promise<DiseaseTypeDto> {
           let returnvalue: DiseaseTypeDto = null;
           await this.RepositoryService.createAsync('DiseaseType/Create_Custom', _DiseaseTypeDto)
             .then((res) => {
                 returnvalue = res as DiseaseTypeDto;
             }).catch (
       (error) => {
               this.errorService.Failed(error);
           });
           return returnvalue;
           }


 async Update_Custom(_DiseaseTypeDto: DiseaseTypeDto): Promise<DiseaseTypeDto> {
               let returnvalue: DiseaseTypeDto = null;
               await this.RepositoryService.updateAsync('DiseaseType/Update_Custom', _DiseaseTypeDto)
                 .then((res) => {
                     returnvalue = res as DiseaseTypeDto;
                 }).catch (
       (error) => {
                   this.errorService.Failed(error);
               });
               return returnvalue;
               }


 async Delete(GUID: string): Promise<boolean> {
                   let returnvalue: boolean = false;
                   await this.RepositoryService.deleteAsync('DiseaseType/Delete_Custom(key=' + GUID + ')')
                     .then((res) => {
                         returnvalue = res as boolean;
                     }).catch (
       (error) => {
                       this.errorService.Failed(error);
                   });
                   return returnvalue;
                   }

               async LoadCombo(): Promise<Combo_DiseaseTypeDto[]> {
 let returnvalue: Combo_DiseaseTypeDto[] = [];
         await this.RepositoryService.getAllAsync('DiseaseType/Get_Combo')
           .then((res) => {
               returnvalue = res['value'] as Combo_DiseaseTypeDto[];
           }).catch (
     (error) => {
             this.errorService.Failed(error);
         });
         return returnvalue;
         }
}
