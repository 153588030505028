import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { SaleDto, List_SaleDto, PList_SaleDto, Save_SaleDto } from './sale.model'
import { Filter_SaleDto } from './filter-sale.model'


const serviceName = "SaleService"


@Injectable({
  providedIn: 'root',
})
export class SaleService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<SaleDto> {
    let returnObject = new SaleDto()
    await this.RepositoryService.getItemAsync('Sale/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<SaleDto> {
    let returnObject = new SaleDto()
    await this.RepositoryService.getItemAsync('Sale/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_Sale) => {
          returnObject = _Sale;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_SaleDto> {
    let returnList = new PList_SaleDto();
    await this.RepositoryService.getAllAsync('Sale/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_SaleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllListFilter(_Filter_SaleDto: Filter_SaleDto, pagingString = ''): Promise<PList_SaleDto> {
    let returnList = new PList_SaleDto();
    await this.RepositoryService.getAllExteraAsync('Sale/Get_AllCustom/' + pagingString, _Filter_SaleDto)
      .then((res) => {
        returnList.Data = res['value'] as List_SaleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(Data: Save_SaleDto): Promise<PList_SaleDto> {
    let returnList = new PList_SaleDto();
    await this.RepositoryService.createAsync('Sale/Create_Custom', Data)
      .then((res) => {
        returnList.Data = res['value'] as List_SaleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnList;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('Sale/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


}
