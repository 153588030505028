

export class CauseTestDto {
    GUID: string;
    Name: string;

}


export class Combo_CauseTestDto {
    GUID: string;
    Name: string;

}


export class List_CauseTestDto {
    GUID: string;
    Name: string;

}
export class PList_CauseTestDto {
Data: CauseTestDto[];
TotalCount: number;
}
