

export class DiseaseTypeDto {
    GUID: string;
    Name: string;

}


export class Combo_DiseaseTypeDto {
    GUID: string;
    Name: string;

}


export class List_DiseaseTypeDto {
    GUID: string;
    Name: string;

}
export class PList_DiseaseTypeDto {
Data: List_DiseaseTypeDto[];
TotalCount: number;
}
