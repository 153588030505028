import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { Injectable } from '@angular/core';


import { ErrorService } from 'src/app/shared/error.service';



const serviceName = "FinalCostFlockTypeDurationInHouse"


@Injectable({
  providedIn: 'root',
})
export class FinalCostFlockTypeDurationInHouseService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }


  async Get_AllFinalCostCycleFlockDurationInHouses(FinalCostCycleOfficeGUID: string, pagingString = ''): Promise<boolean> {
    let returnList: boolean = false;
    await this.RepositoryService.getItemAsync('FinalCostFlockTypeDurationInHouse/Get_AllFinalCostCycleFlockDurationInHouses(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }

}
