

export class PartGroupDto {
    GUID: string;
    Name: string;
    PartMasterGroupGUID: string;
    Code: number;
    RangeFrom: number;
    RangeTo: number;
}


export class Combo_PartGroupDto {
    GUID: string;
    Name: string;

}


export class List_PartGroupDto {
    GUID: string;
    Name: string;
    PartMasterGroupGUID: string;
    PartMasterGroupName: string;
    Code: number;
    RangeFrom: number;
    RangeTo: number;

}
export class PList_PartGroupDto {
Data: List_PartGroupDto[];
TotalCount: number;
}
