

export class SubOfficeHouseModelDto {
    GUID: string;
    OfficeGUID: string;
    SubOfficeGUID: string;
    FertilizeTypeID: number;
    FertilizeTypeGUID: string;
    Name: string;
    Capacity: number;
    isAsigned: boolean;
    isActived: boolean;
    Description: string;

}


export class Combo_SubOfficeHouseModelDto {
    GUID: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    FertilizeTypeGUID: string;
    FertilizeTypeName: string;
    Name: string;
    Capacity: number;
    isAsigned: boolean;
    isActived: boolean;
    Description: string;

}


export class List_SubOfficeHouseModelDto {
    GUID: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    FertilizeTypeGUID: string;
    FertilizeTypeName: string;
    Name: string;
    Capacity: number;
    isAsigned: boolean;
    isActived: boolean;
    Description: string;

}
export class PList_SubOfficeHouseModelDto {
Data: List_SubOfficeHouseModelDto[];
TotalCount: number;
}
