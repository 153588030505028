

export class StockDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    SubOfficeID: number;
    SubOfficeGUID: string;
    StockTypeID: number;
    StockTypeGUID: string;
    Name: string;
    AccCode: number;
    Code: number;
    isUserConfirmStock: number;
}


export class Combo_StockDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    SubOfficeID: number;
    SubOfficeGUID: string;
    StockTypeID: number;
    StockTypeGUID: string;
    Name: string;
    AccCode: number;
    Code: number;
    isUserConfirmStock: number;

}


export class List_StockDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    SubOfficeID: number;
    SubOfficeGUID: string;
    StockTypeID: number;
    StockTypeGUID: string;
    Name: string;
    AccCode: number;
    Code: number;
    isUserConfirmStock: number;

}
export class PList_StockDto {
Data: List_StockDto[];
TotalCount: number;
}
