import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { LangService } from './lang.service';
import { MessageService } from './message.service';

const languageKey = '__lang';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(
    private langService: LangService,
    private toastr: ToastrService,
    private router: Router,
    private messageService: MessageService
  ) { }

  Message = '';
  MessageTitle = 'Seamorgh';
  MessageType = '';

  init(): void {
  }

  // Success(messageKey: any): void {
  //   this.Message = this.langService.TranslateKey('Success.' + messageKey);
  //   this.toastr.success(this.Message, this.MessageTitle);
  // }

  // Error(messageKey: any): void {
  //   this.Message = this.langService.TranslateKey('Error.' + messageKey);
  //   this.toastr.error(this.Message, this.MessageTitle);
  // }

  Failed(err: any): void {
    if (err?.statusText === 'Unknown Error') {
      this.messageService.Warning("⚡ " + ' ارتباط با سرور برقرار نشد!!!🌏','عدم ارتباط با سرور',);
      return ;
    }

    this.ProssesError(err.status , err.error);

  }

  ProssesError(status: any,err: any): void {


    switch (status) {
      case 0 || '0':
        this.Message = this.langService.TranslateKey('Error.0');
        this.Message = this.Message ;
        break;
      case 406:
        this.Message = this.langService.TranslateKey('Error.406');
        this.Message = this.Message;
        break;
      case 401: //login

      localStorage.removeItem('SeamorghUser');
      localStorage.clear();

        this.router.navigate(['/login']);

        this.Message = this.langService.TranslateKey('Error.401');
        this.Message = this.Message;
        break;
      case 403://forbidden
        break;

      default:
      if(err.error != null)  {
      const cerr = err.error as Error;
      if (String(cerr.message).indexOf('The DELETE statement conflicted with the REFERENCE constraint') >= 0) {
        this.Message = this.langService.TranslateKey('Error.DeleteConflictedWithREFERENCEConstraint');
        break;
      }
      if(String(cerr.message).indexOf('Newtonsoft.Json.JsonSerializationException: Error converting value {null} to type') >= 0){
        this.Message="یکی از مقادیر ارسالی به سمت سرور خالی بوده، مجددا فیلدهای اطلاعاتی را از نظر خالی بودن چک نمایید";
        this.Message = this.Message + " (TrackNO: " + cerr.code + ")";
        break;
      }
      if ((String(cerr.message).indexOf('String or binary data would be truncated') >= 0) &&
        (String(cerr.message).indexOf('with unique index') >= 0)) {
        this.Message = this.langService.TranslateKey('Error.DataTruncated');
        break;
      }
      if (String(cerr.message).indexOf('Nullable object must have a value') >= 0) {
        this.Message = this.langService.TranslateKey('Error.NullableObjectMustHaveAValue');
        this.Message = this.Message + " (TrackNO: " + cerr.code + ")";
        break;
      }
      if (String(cerr.message).indexOf('Nullable object must have a value') >= 0) {
        this.Message = this.langService.TranslateKey('Error.NullableObjectMustHaveAValue');
        this.Message = this.Message + " (TrackNO: " + cerr.code + ")";
        break;
      }
      if (String(cerr.message).indexOf(') gt -1') >= 0) {
        this.Message = this.langService.TranslateKey('Error.MakeParameterErrorWhenSearchinList');
        this.Message = this.Message + " (TrackNO: " + cerr.code + ")";
        this.messageService.Warning(this.Message, this.MessageTitle,false);
        return;
        break;
      }
        this.Message = this.langService.TranslateKey('Error.' + cerr.translate);
        this.Message = this.Message + "(TrackNO: " + cerr.code + ")";
      }
        break;
    }

    if (err == null) {
      this.messageService.Error(this.Message,this.MessageTitle,false);
    } else {
      switch (err.errortype) {
        case 'error':
          this.messageService.Error(this.Message,this.MessageTitle,false);
          break;

        case 'warning':
          this.messageService.Warning(this.Message, this.MessageTitle,false);
          break;
        default:
          this.messageService.Error(this.Message,this.MessageTitle,false);
          break;
      }
    }
  }
}

export class Error {
  code: string;
  errortype: string;
  message: string;
  translate: string;
}
