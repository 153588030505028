<div class="authincation h-100" style="background-image: url('./../../../assets/images/Chick2.jpg');background-repeat: no-repeat;
background-size: 50%;background-color: black;">
    <div class="container vh-100">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-lg-6 col-md-8">
                <div class="authincation-content">
                    <div class="row no-gutters">
                        <div class="col-xl-12">
                            <div class="auth-form">
                                <div class="text-center mb-3">
                                    <a [routerLink]="['/admin/index']"><img src="assets/images/logo-full.png"
                                            alt=""></a>
                                </div>
                                <h4 class="text-center mb-4">ورود به سامانه</h4>

                                <form [formGroup]="myForm" (ngSubmit)="myForm.valid && onSubmit()">
                                    <div class="form-group" style="position: relative;">
                                        <label class="mb-1"><strong>نام کاربری / ایمیل</strong></label>
                                        <input type="text" class="form-control" formControlName="Username">
                                        <mat-icon matSuffix style="position: absolute;left: 20px; top: 40px;">person
                                        </mat-icon>
                                    </div>
                                    <div class="form-group" style="position: relative;">
                                        <label class="mb-1"><strong>کلمه عبور</strong></label>
                                        <input type="password" class="form-control" formControlName="Password">
                                        <mat-icon matSuffix style="position: absolute;left: 20px; top: 40px;">
                                            fingerprint</mat-icon>
                                    </div>
                                    <!-- <div class="form-row d-flex justify-content-between mt-4 mb-2">
                                        <div class="form-group">
                                           <div class="custom-control custom-checkbox ml-1">
                                                <input type="checkbox" class="custom-control-input" id="basic_checkbox_1">
                                                <label class="custom-control-label" for="basic_checkbox_1">Remember my preference</label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <a [routerLink]="['/page-forgot-password']">Forgot Password?</a>
                                        </div>
                                    </div> -->
                                    <br />
                                    <br />
                                    <br />
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary btn-block">ورود به سیستم</button>
                                    </div>
                                </form>
                                <!-- <div class="new-account mt-3">
                                    <p>Don't have an account? <a class="text-primary" [routerLink]="['/page-register']">Sign up</a></p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>