import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { Combo_FinalCostFlockDto, FinalCostFlockDto, List_FinalCostFlockDto, PList_FinalCostFlockDto } from './final-cost-flock.model'



const serviceName = "FinalCostFlockService"


@Injectable({
  providedIn: 'root',
})
export class FinalCostFlockService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {
  }


  async Get_FinalCostFlockInformation(FinalCostCycleOfficeGUID: string,pagingString = ''): Promise<boolean> {
    let returnList = false;
    await this.RepositoryService.getAsync('FinalCostFlock/Get_FinalCostFlockInformation(FinalCostCycleOfficeGUID=' + FinalCostCycleOfficeGUID + ')' + pagingString)
      .then((res) => {
        returnList = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }


}
