

export class FlockForecastDto {
    GUID: string;
    BudgetFlockID: number;
    BudgetFlockGUID: string;
    Name: string;
    StartDate: string;
    EndDate: string;
    HenCount: number;
    RoosterCount: number;

}


export class List_FlockForecastDto {
    GUID: string;
    BudgetFlockGUID: string;
    BudgetFlockName: string;
    Name: string;
    StartDate: string;
    EndDate: string;
    HenCount: number;
    RoosterCount: number;

}
export class PList_FlockForecastDto {
Data: List_FlockForecastDto[];
TotalCount: number;
}
