

export class SubOfficeDto {
    GUID: string;
    OfficeID: number;
    OfficeGUID: string;
    Name: string;
    AccCode: number;
    isProductive: boolean;

}


export class Combo_SubOfficeDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    Name: string;
    AccCode: number;
    isProductive: boolean;

}


export class List_SubOfficeDto {
    GUID: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    Name: string;
    AccCode: number;
    isProductive: boolean;

}
export class PList_SubOfficeDto {
Data: List_SubOfficeDto[];
TotalCount: number;
}
