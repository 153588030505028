

export class UseMethodDto {
    GUID: string;
    Name: string;

}


export class Combo_UseMethodDto {
    GUID: string;
    Name: string;

}


export class List_UseMethodDto {
    GUID: string;
    Name: string;

}
export class PList_UseMethodDto {
Data: List_UseMethodDto[];
TotalCount: number;
}
