export class UserDto {
    GUID: string;
    UserTypeGUID: string;
    FullName: string;
    UserName: string;
    Password: string;
    Email: string;
    Address: string;
    ContactNumber: string;
}


export class RegisterDto {
  UserTypeGUID: string;
  LanguageGUID: string;
  UserName: string;
  Password: string;
  Email: string;
}

export class CurrentUser {
    constructor() {
       this.access_token = '';
       this.expires_in = '';
       this.token_type = '';
    }
 
    access_token: string;
    expires_in: string;
    token_type: string;
 }
 
 
 export class UserInfo {
    constructor() {
       this.Email = '';
       this.Name = '';
    }
    Email: string;
    Name: string;
    UserImage: string;
 }
 
 export class LoginDto {
    constructor() {
       this.Username = '';
       this.Password = '';
       this.Captcha = '';
    }
 
    Username: string;
    Password: string;
    Captcha: string;
 }
 


export class ForgetPasswordChangePasswordDto {
   ForgetPasswordGUID: string;
   Code: string;
   Password: string;
}
export class Combo_UserDto
{
   GUID :string;
   Name:string;
}
