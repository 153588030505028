<!--**********************************
    Footer start
***********************************-->
<div class="footer">
    <div class="copyright">
        <p>طراحی با <span style="color:red"> ♥ </span> در انفورماتیک سیمرغ</p>
    </div>
</div>
<!--**********************************
    Footer end
***********************************-->