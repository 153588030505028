

export class EnvironmentParameterDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    sDateShamsi: string;
    sDate: string;
    MinThermometer1: number;
    MaxThermometer1: number;
    MinThermometer2: number;
    MaxThermometer2: number;
    MinThermometer3: number;
    MaxThermometer3: number;
    MinArtificialMother: number;
    MaxArtificialMother: number;
    MinHouseOut: number;
    MaxHouseOut: number;
    HouseDamp: number;
    Description: string;
    isLock: boolean;

}


export class List_EnvironmentParameterDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    sDateShamsi: string;
    sDate: string;
    MinThermometer1: number;
    MaxThermometer1: number;
    MinThermometer2: number;
    MaxThermometer2: number;
    MinThermometer3: number;
    MaxThermometer3: number;
    MinArtificialMother: number;
    MaxArtificialMother: number;
    MinHouseOut: number;
    MaxHouseOut: number;
    HouseDamp: number;
    Description: string;
    isLock: boolean;

    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;

    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    HenStandardValue: number;
    RosterStandardValue: number;

    
    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;


}
export class PList_EnvironmentParameterDto {
Data: List_EnvironmentParameterDto[];
TotalCount: number;
}


export class Save_EnvironmentParameterDto {
    Data: List_EnvironmentParameterDto[];
    GpFlockGUID: string;
    sData: string;
}