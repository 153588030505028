

export class StockInvoiceTypeDto {
    GUID: string;
    Name: string;
    Code: number;

}


export class Combo_StockInvoiceTypeDto {
    GUID: string;
    Name: string;
    Code: number;
}


export class List_StockInvoiceTypeDto {
    GUID: string;
    Name: string;
    Code: number;
}
export class PList_StockInvoiceTypeDto {
Data: List_StockInvoiceTypeDto[];
TotalCount: number;
}
