<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4" *ngIf="LoadFormData">
    <mat-tab label="مشخصات کاربری">
        <div class="container-fluid">
            <div class="row">
                 <app-upload-document ></app-upload-document>
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>نام و نام خانوادگی </mat-label>
                    <input type="text" matInput [(ngModel)]="UserProfileValue.Name" disabled>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>آدرس ایمیل </mat-label>
                    <input type="text" matInput [(ngModel)]="UserProfileValue.email" disabled>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>تلفن همراه </mat-label>
                    <input type="text" matInput [(ngModel)]="UserProfileValue.PhoneNumber" disabled>
                </mat-form-field>
            </div>
        </div>

    </mat-tab>
    <mat-tab label="دسترسی های شما">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <b style="font-family: iranSans;">دسترسی شما به شعب:</b>
                </div>
                <div class="col-12">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngFor="let label of SubOfficeNameLists">{{label}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <b style="font-family: iranSans;">نقشهای کاربری شما در نرم افزار:
                    </b>
                </div>
                <div class="col-12">
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip *ngFor="let label of UserRoleNameLists">{{label}}</mat-chip>
                    </mat-chip-list>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="تغییر کلمه عبور">
        <div class="container-fluid">
            <div class="row">
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>کلمه عبور قدیمی</mat-label>
                    <input type="password" matInput [(ngModel)]="OldPassword" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>کلمه عبور جدید</mat-label>
                    <input type="password" matInput [(ngModel)]="NewPassword" (blur)="NewPassowrdLostFocus()" required>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <mat-label>تکرار کلمه عبور جدید</mat-label>
                    <input type="password" matInput [(ngModel)]="NewPasswordReEnter"
                        (blur)="NewPassowrdReEnterLostFocus()" required>
                </mat-form-field>

            </div>
            <div class="row">
                <div class="col-12" style="padding: 5px;">
                    <button type="button" mat-button class="btn-success" (click)="DoChangePassword()">تغییر کلمه عبور</button>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
