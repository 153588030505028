

export class GpPartDto {
    GUID: string;
    PartGroupGUID: string;
    PartGUID: string;
    Name: string;
    isActive: boolean;

}


export class Combo_GpPartDto {
    GUID: string;
    PartGUID: string;
    PartName: string;
    Name: string;
    isActive: boolean;

}


export class List_GpPartDto {
    GUID: string;
    PartGroupGUID: string;
    PartGroupName: string;
    PartGUID: string;
    PartName: string;
    Name: string;
    isActive: boolean;

}
export class PList_GpPartDto {
Data: List_GpPartDto[];
TotalCount: number;
}
