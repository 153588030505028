

export class EggTypeDto {
    GUID: string;
    EggGroupID: number;
    EggGroupGUID: string;
    EggStatusID: number;
    EggStatusGUID: string;
    Name: string;

}


export class Combo_EggTypeDto {
    GUID: string;
    EggGroupGUID: string;
    EggGroupName: string;
    EggStatusGUID: string;
    EggStatusName: string;
    Name: string;

}


export class List_EggTypeDto {
    GUID: string;
    EggGroupGUID: string;
    EggGroupName: string;
    EggStatusGUID: string;
    EggStatusName: string;
    Name: string;

}
export class PList_EggTypeDto {
Data: List_EggTypeDto[];
TotalCount: number;
}
