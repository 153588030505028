import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import { RepositoryService } from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { Combo_FinalCostCycleDto, FinalCostCycleDto, List_FinalCostCycleDto, PList_FinalCostCycleDto } from './final-cost-cycle.model'



const serviceName = "FinalCostCycleService"


@Injectable({
  providedIn: 'root',
})
export class FinalCostCycleService {
  constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ) {
  }


  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<FinalCostCycleDto> {
    let returnObject = new FinalCostCycleDto()
    await this.RepositoryService.getItemAsync('FinalCostCycle/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<FinalCostCycleDto> {
    let returnObject = new FinalCostCycleDto()
    await this.RepositoryService.getItemAsync('FinalCostCycle/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_FinalCostCycle) => {
          returnObject = _FinalCostCycle;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_FinalCostCycleDto> {
    let returnList = new PList_FinalCostCycleDto();
    await this.RepositoryService.getAllAsync('FinalCostCycle/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostCycleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllList(pagingString = ''): Promise<PList_FinalCostCycleDto> {
    let returnList = new PList_FinalCostCycleDto();
    await this.RepositoryService.getAllAsync('FinalCostCycle/Get_All/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_FinalCostCycleDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(_FinalCostCycleDto: FinalCostCycleDto): Promise<FinalCostCycleDto> {
    let returnvalue: FinalCostCycleDto = null;
    await this.RepositoryService.createAsync('FinalCostCycle/Create_Custom', _FinalCostCycleDto)
      .then((res) => {
        returnvalue = res as FinalCostCycleDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Update_Custom(_FinalCostCycleDto: FinalCostCycleDto): Promise<FinalCostCycleDto> {
    let returnvalue: FinalCostCycleDto = null;
    await this.RepositoryService.updateAsync('FinalCostCycle/Update_Custom', _FinalCostCycleDto)
      .then((res) => {
        returnvalue = res as FinalCostCycleDto;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycle/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }


  async Close(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycle/Close_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }

  async Open(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('FinalCostCycle/Open_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }



  async LoadCombo(GetAll: boolean=false): Promise<Combo_FinalCostCycleDto[]> {
    let returnvalue: Combo_FinalCostCycleDto[] = [];
    await this.RepositoryService.getAllAsync('FinalCostCycle/Get_Combo(GetAll='+GetAll+')')
      .then((res) => {
        returnvalue = res['value'] as Combo_FinalCostCycleDto[];
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          returnvalue = undefined;
        });
    return returnvalue;
  }
}
