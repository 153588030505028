import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
 
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

   constructor(private http: HttpClient) { }


  public get = (route: string): Observable<any> => {
    return this.http.get<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders());
  }

  public post = (route: string, body: any) => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }

  public getItem = (route: string, Code: any): Observable<any> => {
    return this.http.get<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion) + Code, this.generateHeaders());
  }

  public async getAsync(route: string): Promise<any> {
    return await this.http.get<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders()).toPromise();
  }

  public async getItemAsync(route: string): Promise<any> {
    return await this.http.get<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders()).toPromise();
  }
  public getItemExtera = (route: string, body: any): Observable<any> => {
    return this.http.post<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }
  public async getItemExteraAsync(route: string, body: any): Promise<any> {
    return await this.http.post<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders() ).toPromise();
  }
  public getAll = (route: string):
    Observable<any[]> => {
    return this.http.get<any[]>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders());
  }
  public async getAllAsync(route: string): Promise<any[]> {
    return await this.http.get<any[]>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders()).toPromise();
  }

  public getAllExtera = (route: string, body: any):
    Observable<any[]> => {
    return this.http.post<any[]>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }
  public async getAllExteraAsync(route: string, body: any): Promise<any[]> {
    return await this.http.post<any[]>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders()).toPromise();
  }

  public getCombo = (route: string): Observable<any[]> => {
    return this.http.get<any[]>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders());
  }



  public create = (route: string, body: any) => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }
  public async createAsync(route: string, body: any): Promise<any> {
    return await this.http.post<any>(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders()).toPromise();
  }

  public update = (route: string, body: any) => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }


  public updateAsync = (route: string, body: any): Promise<any> => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders()).toPromise();
  }

  public patch = (route: string, body: any) => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }

  public delete = (route: string) => {
    return this.http.get(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders());
  }

  public deleteAsync = (route: string): Promise<any> => {
    return this.http.get(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateHeaders()).toPromise();
  }

  public deleteExtera = (route: string, body: any) => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateHeaders());
  }


  public Download = (route: string):
  Promise<any> => {
    return this.http.get(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), this.generateBlobHeaders()).toPromise();
  }

  public DownloadExtera = (route: string, body: any):
    Observable<any> => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, this.generateBlobHeaders());
  }

  public Upload = (route: string, body: any): Observable<any> => {
    return this.http.post(this.createOdataCompleteRoute(route, environment.odataUrl, environment.apiVersion), body, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public async UploadAsync(route: string, body: any): Promise<any> {
    return await this.http.post<any>(this.createOdataCompleteRoute(route, environment.apiUrl, environment.apiVersion), body , {
      reportProgress: true,
      observe: 'events'
    }).toPromise();
  }

  public login = (route: string, body: any) => {
    return this.http.post(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion), body, this.generateHeaderLogin(body.Captcha));
  }


  public apiPost = (route: string, body: any) => {
    return this.http.post(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion), body, this.generateHeaders());
  }

  public apiGet = (route: string) => {
    return this.http.get(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion));
  }

  public apiGetCaptch = (route: string) => {
    return this.http.get(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion) ,this.generateBlobHeaders());
  }


  public loginAsync(route: string, body: any): Promise<any> {
    return this.http.post(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion), body).toPromise();
  }

  public logoutAsync(route: string): Promise<any> {
    return this.http.get(this.createApiCompleteRoute(route, environment.apiUrl, environment.apiVersion)).toPromise();
  }
  

  private createOdataCompleteRoute = (route: string, envAddress: string, version: string) => {
    return `${envAddress}/${route}`;
  }


  private createApiCompleteRoute = (route: string, envAddress: string, version: string) => {
    return `${envAddress}/${version}/${route}`;
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'  , 'ActiveSoftwareGUID': environment.SoftwareGUID})
    };
  }

  private generateBlobHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' ,  responseType: 'blob'  , 'Access-Control-Allow-Origin': '*'  , 'ActiveSoftwareGUID': environment.SoftwareGUID})
    };
  }


  private generateUploadHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' ,  responseType: 'blob'  , 'Access-Control-Allow-Origin': '*'  , 'ActiveSoftwareGUID': environment.SoftwareGUID})
    };
  }

  private generateHeaderLogin = (Captcha: string) => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' ,  'captcha' : Captcha })
    };
  }
}


