

export class SLDto {
    GUID: string;
    Name: string;
    AccCode: number;

}


export class Combo_SLDto {
    GUID: string;
    Name: string;
    AccCode: number;

}


export class List_SLDto {
    GUID: string;
    Name: string;
    AccCode: number;

}
export class PList_SLDto {
    Data: List_SLDto[];
    TotalCount: number;
}
