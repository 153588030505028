

export class LightingProgramDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    LightingProgramTypeID: number;
    LightingProgramTypeGUID: string;
    LightColorID: number;
    LightColorGUID: string;
    sDateShamsi: string;
    sDate: string;
    LightStartTime: string;
    LightEndTime: string;
    LightIntensity: number;
    Description: string;
    isLock: boolean;

}


export class List_LightingProgramDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    LightingProgramTypeGUID: string;
    LightingProgramTypeName: string;
    LightColorGUID: string;
    LightColorName: string;
    sDateShamsi: string;
    sDate: string;
    LightStartTime: string;
    LightEndTime: string;
    LightIntensity: number;
    Description: string;
    isLock: boolean;


    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;

}
export class PList_LightingProgramDto {
Data: List_LightingProgramDto[];
TotalCount: number;
}



export class Save_LightingProgramDto {
    Data: List_LightingProgramDto[];
    GpFlockGUID: string;
    sData: string;
}