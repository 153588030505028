

export class FertilizeTypeDto {
    GUID: string;
    Name: string;
    ProducePercent: number;

}


export class Combo_FertilizeTypeDto {
    GUID: string;
    Name: string;
    ProducePercent: number;

}


export class List_FertilizeTypeDto {
    GUID: string;
    Name: string;
    ProducePercent: number;

}
export class PList_FertilizeTypeDto {
Data: List_FertilizeTypeDto[];
TotalCount: number;
}
