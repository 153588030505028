export class SamplingDto {
    GUID: string;
    HouseHenCount: number;
    HouseRoosterCount: number;
    HouseID: number;
    HouseGUID: string;
    SamplingTypeID: number;
    SamplingTypeGUID: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    TypeSampleGUID: string;
    TypeTestGUID: string;
    TypeKitGuid: string;
    CauseTestGUID: string;
    LabPlaceGUID: string;
    ResultSample: string;
    OperatorName: string;
    Description: string;
    isLock: boolean;

}


export class List_SamplingDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    SamplingTypeGUID: string;
    SamplingTypeName: string;
    sDateShamsi: string;
    sDate: string;
    HenCount: number;
    RoosterCount: number;
    TypeSampleGUID: string;
    TypeTestGUID: string;
    TypeKitGUID: string;
    CauseTestGUID: string;
    LabPlaceGUID: string;
    ResultSample: string;
    OperatorName: string;
    Description: string;
    isLock: boolean;


    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    HenStandardValue: number;
    RosterStandardValue: number;


    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;
}
export class PList_SamplingDto {
Data: List_SamplingDto[];
TotalCount: number;
}



export class Save_SamplingDto {
    Data: List_SamplingDto[];
    GpFlockGUID: string;
    sData: string;
}

