

export class StockAmountDto {
    GUID: string;
    FinalCostCycleOfficeID: number;
    FinalCostCycleOfficeGUID: string;
    SLID: number;
    SLGUID: string;
    DL4ID: number;
    DL4GUID: string;
    DL5ID: number;
    DL5GUID: string;
    DL6ID: number;
    DL6GUID: string;
    Amount: number;
    ServiceAmount: number;

}


export class List_StockAmountDto {
    GUID: string;
    FinalCostCycleOfficeGUID: string;
    FinalCostCycleOfficeName: string;
    OfficeGUID: string;
    OfficeName: string;
    SubOfficeGUID: string;
    SubOfficeName: string;
    DL5GUID: string;
    DL5Name: string;
    DL5AccCode: number;
    DL6GUID: string;
    DL6Name: string;
    DL6AccCode: number;
    PartGUID: string;
    PartName: string;
    StockGUID: string;
    StockName: string;
    AmountIn: number;
    CostIn: number;
    AmountOut: number;
    CostOut: number;

}
export class PList_StockAmountDto {
Data: List_StockAmountDto[];
TotalCount: number;
}


export class FilterStockAmountDto{
    OfficeGUID: string;
    FinalCostCycleOfficeGUIDs: string[];
    SubOfficeGUIDs: string[];
    DLTypeGUID: string;
    DLGUIDs: string[];
    DL6GUIDs: string[];
    StockGUIDs: string[];
}


