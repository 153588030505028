

export class EggCollectDto {
    GUID: string;
    HouseID: number;
    HouseGUID: string;
    PersonnelName: string;
    sDateShamsi: string;
    sDate: string;
    CollectTime: string;
    ShiftNumber: number;
    isLock: boolean;
    EggCollectDetails: EggCollectDetailDto[] = [];
}

export class EggCollectDetailDto {
    EggCollectGUID: string;
    EggTypeID: number;
    EggTypeGUID: string;
    EggTypeName: string;
    EggStatuseID: number;
    EggStatuseName: string;
    EggCount: number;
    isLock: boolean;

}

export class List_EggCollectDto {
    GUID: string;
    HouseGUID: string;
    HouseName: string;
    PersonnelName: string;
    sDateShamsi: string;
    sDate: string;
    CollectTime: string;
    ShiftNumber: number;
    isLock: boolean;
    EggStoreGUID: string;
    EggStoreName: string;
    EggCollectDetails: EggCollectDetailDto[] = [];
    
    BreedGUID: string;
    BreedName: string;
    BreedStandardGUID: string;
    BreedStandardName: string;
    WeekNO : number;
    FlockIsMother:boolean;
    FlockTypeGUID: string;
    FlockTypeName: string;
    WeekDayNO : number;
    LifeCycleTypeGUID: string;
    LifeCycleTypeName: string;
    HenStandardValue: number;

    HouseHenCount: number;
    HouseRoosterCount: number;
    isDuplicated:boolean;
    HasError:boolean;
    TotalEggInHouse: number;
    ActiveRow: number;
}
export class PList_EggCollectDto {
    Data: List_EggCollectDto[];
    TotalCount: number;
}

export class Save_EggCollectDto {
    Data: List_EggCollectDto[];
    GpFlockGUID: string;
    sData: string;
}