import { GeneralService } from 'src/app/general-components/general-service/general.service';
import { ToastrService } from 'ngx-toastr';
import {RepositoryService} from 'src/app/_services/repository.service';
import { LangService } from 'src/app/shared/lang.service';
import { ErrorService } from '../../../../shared/error.service';
import { Injectable } from '@angular/core';


import { EnvironmentParameterDto, List_EnvironmentParameterDto, PList_EnvironmentParameterDto, Save_EnvironmentParameterDto } from './environment-parameter.model'
import { Filter_EnvironmentParameterDto } from './filter-environment-parameter.model'


const serviceName= "EnvironmentParameterService"


@Injectable({
 providedIn: 'root',
})
export class EnvironmentParameterService {
constructor(
    protected RepositoryService: RepositoryService,
    protected toastr: ToastrService,
    protected langService: LangService,
    protected errorService: ErrorService,
    protected generalService: GeneralService
  ){
  }

  ngOnInit(): void {


  }

  async Get_Item(GUID = ''): Promise<EnvironmentParameterDto> {
    let returnObject = new EnvironmentParameterDto()
    await this.RepositoryService.getItemAsync('EnvironmentParameter/Get_Item(key=' + GUID + ')')
      .then(
        (res) => {
          returnObject = res;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnObject;
  }


  async Get_ItemCustom(GUID = '', AutoEmit = true): Promise<EnvironmentParameterDto> {
    let returnObject = new EnvironmentParameterDto()
    await this.RepositoryService.getItemAsync('EnvironmentParameter/Get_ItemCustom(key=' + GUID + ')')
      .then(
        (_EnvironmentParameter) => {
          returnObject = _EnvironmentParameter;
        }
      ).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnObject;
  }


  async Get_AllCustom(pagingString = ''): Promise<PList_EnvironmentParameterDto> {
    let returnList = new PList_EnvironmentParameterDto();
    await this.RepositoryService.getAllAsync('EnvironmentParameter/Get_AllCustom/' + pagingString)
      .then((res) => {
        returnList.Data = res['value'] as List_EnvironmentParameterDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Get_AllListFilter(_Filter_EnvironmentParameterDto: Filter_EnvironmentParameterDto, pagingString = ''): Promise<PList_EnvironmentParameterDto> {
    let returnList = new PList_EnvironmentParameterDto();
    await this.RepositoryService.getAllExteraAsync('EnvironmentParameter/Get_AllCustom/' + pagingString, _Filter_EnvironmentParameterDto)
      .then((res) => {
        returnList.Data = res['value'] as List_EnvironmentParameterDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        }
      );
    return returnList;
  }



  async Create_Custom(Data: Save_EnvironmentParameterDto): Promise<PList_EnvironmentParameterDto> {
    let returnList = new PList_EnvironmentParameterDto();
    await this.RepositoryService.createAsync('EnvironmentParameter/Create_Custom', Data)
      .then((res) => {
        returnList.Data = res['value'] as List_EnvironmentParameterDto[];
        returnList.TotalCount = res['@odata.count'] as number;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
          return undefined;
        });
    return returnList;
  }


  async Delete(GUID: string): Promise<boolean> {
    let returnvalue: boolean = false;
    await this.RepositoryService.deleteAsync('EnvironmentParameter/Delete_Custom(key=' + GUID + ')')
      .then((res) => {
        returnvalue = res as boolean;
      }).catch(
        (error) => {
          this.errorService.Failed(error);
        });
    return returnvalue;
  }
}
